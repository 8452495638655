import {
  GridColumns,
  GridFilterModel,
  GridRowData,
  GridSortModel,
  GridSelectionModel,
  GridInputSelectionModel,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarColumnsButton,
  XGrid,
  GridColDef,
  GridRowId,
  GridFooter, GridColumnResizeParams, GridRowIdGetter, GridCellParams,
  GridSlotsComponent, GridSortItem, GridColumnHeaderParams, GridFilterItem,
  GridFooterContainer, GridPagination,
} from '@material-ui/x-grid';
import React, {
  useEffect, useLayoutEffect, useRef, MouseEventHandler, useCallback, useState,
} from 'react';
import {
  Link, useNavigate, useLocation, useNavigationType,
} from 'react-router-dom';
import { QueryResult, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { GridRowParams } from '@material-ui/data-grid';
import Box from '@material-ui/core/Box';
import ClearFilterIcon from '@images/icons/svg/clearFilter.svg';
import FirstPageIcon from '@images/icons/svg/firstPage.svg';
import LastPageIcon from '@images/icons/svg/lastPage.svg';
import { RolesTypes } from 'types/acl';
import {
  DEFAULT_ROWS_PER_PAGE,
  DEFAULT_ROWS_PER_PAGE_OPTIONS,
  TABLE_FILTER_MODE,
  TABLE_PAGINATION_MODE, TABLE_SORTING_MODE,
} from './utils/utils';
import TitleView from './layout/TitleView';
import {
  TABLE_CONFIG, TableConfig, TableConfigRes, TableConfigVars, ColumnType,
} from '../query/layout';
import { useQueryParams } from './utils/hooks';
import {
  saveQueryParams, loadQueryParams, encodeTextToBase64, decodeBase64ToText, QueryParamsI,
} from '../storage/tableSettingsCache';
import BaseButton from './form/BaseButton';
import FirstTimeRender from '../lib/FirstTimeRender';
import TeamCheckWrapper from './TeamCheckWrapper';
import { UserContext } from './ACLWrapper';

export const getQueryParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return Object.fromEntries(urlSearchParams.entries());
};

export const tableDefaultSortModel = [{ field: 'date', sort: 'desc' }] as GridSortItem[];
export const defaultFilterModel = { items: [] };

export type TableOptions = {
  filterText?: string;
  rowsPerPage: number;
  page: number;
  sort?: string;
};

declare module '@material-ui/x-grid' {
  // eslint-disable-next-line no-shadow
  interface GridColDef {
    useTextLengthAsWidth?: boolean
  }
}

export const getHeaderWidthFromLength = (text: string) => Math.ceil(text.length * 11 + 24);

const ResetToolbarButton = (props: {
  resetFn: MouseEventHandler<HTMLButtonElement>,
  enableReset?: boolean
}) => {
  const { enableReset, resetFn } = props;
  const { t } = useTranslation();

  return (
    <Box style={{ marginLeft: '-8px' }}>
      <BaseButton onClick={resetFn} disabled={!enableReset} startIcon={<ClearFilterIcon />}>
        {t('table.reset', 'reset')}
      </BaseButton>
    </Box>
  );
};

function CustomToolbar(props: {
  user?: {isStaff:boolean, isSupportStaff: boolean} & RolesTypes | null,
  resetFn?: MouseEventHandler<HTMLButtonElement>,
  enableReset?: boolean,
  enableExport?: boolean,
}) {
  const {
    user, resetFn, enableReset, enableExport,
  } = props;

  return (
    <GridToolbarContainer
      style={{ marginBottom: '12px' }}
    >
      { user?.isSupportStaff && enableExport && <GridToolbarColumnsButton /> }
      <GridToolbarFilterButton disableHoverListener />
      {resetFn && (
      <ResetToolbarButton
        resetFn={resetFn}
        enableReset={enableReset}
      />
      )}
      {user?.isSupportStaff && enableExport && <GridToolbarExport /> }
    </GridToolbarContainer>
  );
}

function CustomPagination(props: {
  options: TableOptions
  setOptions: (options: any) => void;
  onOptionsChange: (options: TableOptions) => void;
  lastPage: number,
}) {
  const {
    options, setOptions, onOptionsChange, lastPage,
  } = props;

  return (
    <GridFooterContainer>
      <button
        className="first-page-button pagination-arrows"
        aria-label={options.page === 0 ? 'disabled' : 'enabled'}
        type="submit"
        onClick={() => {
          setOptions((prev) => ({ ...prev, page: 0 }));
          onOptionsChange({ ...options, page: 0 });
        }}
      >
        <FirstPageIcon fill={options.page === 0 ? 'var(--border-disabled)' : 'var(--secondary-color)'} />
      </button>
      <GridPagination />
      <button
        className="last-page-button pagination-arrows"
        aria-label={options.page === lastPage ? 'disabled' : 'enabled'}
        type="submit"
        onClick={() => {
          setOptions((prev) => ({ ...prev, page: lastPage }));
          onOptionsChange({ ...options, page: lastPage });
        }}
      >
        <LastPageIcon fill={options.page === lastPage ? 'var(--border-disabled)' : 'var(--secondary-color)'} />
      </button>
    </GridFooterContainer>
  );
}

function TableFooter(props: any) {
  return (
    <GridFooter
      {...props}
    />
  );
}

export interface PropsIn {
  checkboxSelection?: boolean;
  className?: string
  clientSorting?: boolean;
  columns?: GridColumns;
  compactHeader?: boolean;
  compactRows?: boolean;
  components?: GridSlotsComponent
  context?: TableConfig;
  customFilter?: object,
  customFooter?: React.FC;
  defaultSort?: string;
  defaultSortModel?: GridSortModel;
  disableColumnFilter?: boolean;
  disableColumnReorder?: boolean;
  disableColumnResize?: boolean;
  disableCursor?: boolean;
  disableQueryParamsUpdate?: boolean;
  enableColumnSelector?: boolean,
  enableExport?: boolean,
  error?: any;
  footerTotalText?: string,
  getRowClassName?: (params: GridRowParams) => string;
  getRowId?: GridRowIdGetter;
  hideFooter?: boolean;
  hideTopFilters?: boolean;
  id?: string; // @todo should be required to use the correct query params state
  linkNavigation?: boolean,
  loading?: boolean;
  onFilterModelChange?: (model: GridFilterModel, details?: any) => void;
  onOptionsChange?: (options: TableOptions) => void;
  onResetClick?: () => void;
  onRowClick?: (params: GridRowParams) => void;
  onSelectionModelChange?: (selectionModel: GridSelectionModel, details?: any) => void;
  onSortModelChange?: (model: GridSortModel | undefined, details?: any) => void;
  options?: TableOptions;
  rowClickLink?: string;
  rows: GridRowData[];
  rowsPerPage?: number;
  rowsPerPageOptions?: number[];
  scrollHorizontally?: boolean;
  selectionModel?: GridInputSelectionModel;
  setFilters?: (filters: any) => void;
  setOptions?: (options: any) => void;
  sortModel?: GridSortModel;
  tableTitle?: string;
  tableTitleAlignment?: 'left' | 'center' | 'right';
  tableTitleSmallMargin?: boolean;
  teamId?: string
  templateId?: string;
  totalRows?: number;
}

export const TableCellPlaceholder = () => <div className="table-cell-placeholder loading-gradient" />;

export const mergeTableRowsById = (dataA: {id: string}[], dataB: {id: string}[]) => dataA?.map((d) => ({
  ...d,
  ...(dataB?.find((e) => e.id === d.id) || {}),
})) || [];

export const renderAlignedTopHeader = (params: GridColumnHeaderParams) => (
  <Box className="MuiDataGrid-columnHeaderTitle vertical-top">
    <span>{params.colDef.headerName}</span>
  </Box>
);

const tableDataRegex = /td(\d+)_(options|filters|sortModel)-(.*)/;

export default function Table(props: PropsIn) {
  const { t } = useTranslation();
  const {
    checkboxSelection,
    className,
    clientSorting,
    columns,
    compactHeader,
    compactRows,
    components,
    context,
    customFooter,
    defaultSort,
    defaultSortModel,
    disableColumnFilter,
    disableColumnReorder,
    disableColumnResize,
    disableCursor,
    disableQueryParamsUpdate,
    error,
    getRowId,
    getRowClassName,
    hideFooter,
    hideTopFilters,
    id,
    linkNavigation,
    loading,
    onFilterModelChange,
    options: paramsOptions,
    onOptionsChange,
    onSelectionModelChange,
    onSortModelChange,
    onResetClick,
    onRowClick,
    rowClickLink,
    rows,
    rowsPerPage,
    rowsPerPageOptions,
    scrollHorizontally,
    selectionModel,
    setFilters,
    setOptions: paramsSetOptions,
    sortModel,
    tableTitle,
    tableTitleAlignment,
    tableTitleSmallMargin,
    teamId,
    templateId,
    totalRows,
    customFilter,
    enableExport,
    enableColumnSelector,
    footerTotalText,
  } = props;

  const initialized = React.useRef(false);
  const { user, userCan } = React.useContext(UserContext);
  const filterFieldsReader = [
    'athlete',
    'category',
    'teamsession__category__name',
    'created_on',
    'date',
    'end_date',
    'processed_on',
    'start_date',
    'start_timestamp',
    'tags',
  ]; // filter fields a user reader type can view
  const tableRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const navType = useNavigationType();
  const optionsKeyFn = (team: string | undefined) => `td${team}_options${id ? `-${id}` : ''}`;
  const sortModelKeyFn = (team: string | undefined) => `td${team}_sortModel${id ? `-${id}` : ''}`;
  const filtersKeyFn = (team: string | undefined) => `td${team}_filters${id ? `-${id}` : ''}`;

  const optionsKey = optionsKeyFn(teamId);
  const sortModelKey = sortModelKeyFn(teamId);
  const filtersKey = filtersKeyFn(teamId);

  const defaultTableOptions = {
    rowsPerPage: rowsPerPage || DEFAULT_ROWS_PER_PAGE,
    page: 0,
    sort: defaultSort || '-track__timestamp',
  };

  // const queryParamsFromDb = teamId && id ? loadQueryParams(teamId, id) : undefined;
  const [localColumnsWidth, setLocalColumnsWidth] = React.useState<{[k: string]: number}>({});
  const [visibleColumns, setVisibleColumns] = React.useState<null | number>(null);
  const [resizeMonitor, setResizeMonitor] = React.useState(0);
  const [localFiltersModel, setLocalFiltersModel] = React.useState<GridFilterModel | undefined>(
    undefined,
  );
  const updatingParams = React.useRef<boolean>(false);
  const [queryParams, setQueryParams] = useQueryParams();
  const [localOptions, setLocalOptions] = useState<TableOptions>(defaultTableOptions);
  const [nextTeamId, setNextTeamId] = useState<string | undefined>();

  const options = paramsOptions || localOptions;
  const setOptions = paramsSetOptions || setLocalOptions;

  const cleanQueryParams = (team: string, nextQueryParams: QueryParamsI) => {
    const queryParamsClone = { ...nextQueryParams };

    Object.keys(queryParamsClone).forEach((queryParamsKey) => {
      const keyParts = queryParamsKey.match(tableDataRegex);

      if (keyParts && keyParts[1] && keyParts[1] !== team) {
        // ci sono parametri di altri team sul url, puliamo prima di caricare i nuovi
        delete queryParamsClone[queryParamsKey];
      }
    });

    queryParamsClone.filtersTeam = team;
    setQueryParams(queryParamsClone, { replace: !initialized.current });

    return queryParamsClone;
  };

  const saveQueryParamsWithCleaning = (tId: string, i: string | undefined, newParams: QueryParamsI) => {
    if (!disableQueryParamsUpdate) {
      const cleanedParams = cleanQueryParams(tId, newParams);
      saveQueryParams(tId, i, cleanedParams);
    }
  };

  const handleOptionsChange = (newOptions: Partial<TableOptions>) => {
    updatingParams.current = true;
    const newOptionsObj = { ...options, ...newOptions };
    const newParams = {
      ...queryParams,
      ...{
        [optionsKey]: encodeTextToBase64(JSON.stringify(newOptionsObj)),
        filtersTeam: teamId,
      },
    };

    if (teamId) {
      saveQueryParamsWithCleaning(teamId, id, newParams);
    }

    if (setOptions) {
      setOptions(newOptionsObj);
    }

    if (onOptionsChange) {
      onOptionsChange(newOptionsObj);
    }
    updatingParams.current = false;
  };

  const handleSortModelChange = (model: GridSortModel | undefined, details: any, dontUpdateQueryParams = false) => {
    updatingParams.current = true;
    const newParams = {
      ...queryParams,
      ...{
        [sortModelKey]: model
          ? model
            .map((sortItem) => `${sortItem.sort === 'desc' ? '-' : ''}${sortItem.field}`)
          : defaultSortModel || tableDefaultSortModel,
        filtersTeam: teamId,
      },
    };

    if (!dontUpdateQueryParams && teamId) {
      saveQueryParamsWithCleaning(teamId, id, newParams);
    }

    if (onSortModelChange) {
      onSortModelChange(model, details);
    }
    updatingParams.current = false;
  };

  const handleFilterModelChange = (model: GridFilterModel, details: any, dontUpdateQueryParams = false) => {
    updatingParams.current = true;
    // @todo SECURITY test
    const newParams = {
      ...queryParams,
      ...{
        [filtersKey]: encodeTextToBase64(JSON.stringify(model)),
        filtersTeam: teamId,
      },
    };

    if (!dontUpdateQueryParams && teamId) {
      saveQueryParamsWithCleaning(teamId, id, newParams);
    }
    setLocalFiltersModel(model);

    if (onFilterModelChange) {
      onFilterModelChange(model, details);
    }

    updatingParams.current = false;
  };

  const handleResetAll = () => {
    // reset all filters/options on page, query params and localStorage:
    updatingParams.current = true;
    const newParams = {
      ...queryParams,
      ...{
        [filtersKey]: encodeTextToBase64(JSON.stringify(defaultFilterModel)),
        [optionsKey]: encodeTextToBase64(JSON.stringify(defaultTableOptions)),
        [sortModelKey]: defaultSortModel
          ? defaultSortModel
            .map((sortItem) => `${sortItem.sort === 'desc' ? '-' : ''}${sortItem.field}`)
          : defaultSortModel || tableDefaultSortModel,
        filtersTeam: teamId,
      },
    };
    if (teamId) {
      saveQueryParamsWithCleaning(teamId, id, newParams);
    }
    setQueryParams(newParams);
    setLocalFiltersModel(defaultFilterModel);
    if (setOptions) {
      setOptions(defaultTableOptions);
    }
    updatingParams.current = false;
  };

  const updateModels = (dontUpdateQueryParams = false) => {
    const qp = getQueryParams();
    if (!updatingParams.current) {
      updatingParams.current = true;

      const isFirstRender = FirstTimeRender.isFirstTime();
      const startFromQueryOrStorage = isFirstRender || dontUpdateQueryParams;
      const loadTeamId = startFromQueryOrStorage
        ? qp.filtersTeam || teamId
        : teamId;

      if (loadTeamId) {
        if (loadTeamId !== teamId) {
          // arrivo da un link con team diverso, cambio team
          setNextTeamId(loadTeamId);
        } else {
          if (qp.filtersTeam !== loadTeamId) {
            // sto probabilmente tornando indietro a un altro team
            initialized.current = false;
          }

          const queryParamsFromCache = loadQueryParams(loadTeamId, id);
          const initialSortModel = startFromQueryOrStorage
            ? qp[sortModelKeyFn(loadTeamId)]
            : (queryParamsFromCache && queryParamsFromCache[sortModelKeyFn(loadTeamId)]);
          const initialFilters = startFromQueryOrStorage
            ? qp[filtersKeyFn(loadTeamId)]
            : (queryParamsFromCache && queryParamsFromCache[filtersKeyFn(loadTeamId)]);

          // restore sort model
          if (onSortModelChange) {
            handleSortModelChange(
              initialSortModel
                ? (Array.isArray(initialSortModel)
                  ? initialSortModel
                  : initialSortModel
                    ? initialSortModel.split(',')
                    : []
                ).map((s: string) => ({
                  field: s[0] === '-' ? s.substring(1) : s,
                  sort: s[0] === '-' ? 'desc' : 'asc',
                })) : defaultSortModel,
              null,
              dontUpdateQueryParams,
            );
          }

          // restore filters
          if (onFilterModelChange) {
            handleFilterModelChange(
              initialFilters
                ? JSON.parse(decodeBase64ToText(initialFilters))
                : defaultFilterModel,
              null,
              dontUpdateQueryParams,
            );
          }
        }
      }
    }
    setTimeout(() => {
      initialized.current = true;
    }, 1000);
    updatingParams.current = false;
  };

  useEffect(() => {
    // @todo valutare l'uso di location.key !== 'default'
    if (navType === 'POP') {
      initialized.current = false;
      updateModels(true);
    }
  }, [location]);

  useEffect(() => {
    updateModels();
  }, [
    id,
    teamId,
  ]);

  useEffect(() => {
    if (customFilter) {
      const model = {
        items: [
          customFilter,
        ],
      };
      handleFilterModelChange(model, null);
    }
  }, [customFilter]);

  /*
  useEffect(() => {
    if (onOptionsChange) {
      onOptionsChange(options);
    }
  }, [options]); */

  const formatLink = (linkId: GridRowId) => rowClickLink?.replace('{id}', linkId as string);

  useLayoutEffect(() => {
    if (tableRef?.current?.offsetWidth && tableRef?.current?.offsetWidth > 0) {
      let acc = 20;
      columns?.every((curr, i) => {
        const colWidth = localColumnsWidth[curr.field] || curr.minWidth || curr.width || 100;
        // @ts-ignore
        if (acc + colWidth < tableRef.current.offsetWidth) {
          setVisibleColumns(i + 1);
          acc += colWidth;
          return true;
        }
        return false;
      });
    }
  }, [columns, options, window.innerWidth, tableRef?.current?.offsetWidth, resizeMonitor, localColumnsWidth]);

  let tableConfigResponse: null | QueryResult<TableConfigRes, TableConfigVars> = null;
  if (templateId && context) {
    tableConfigResponse = useQuery<TableConfigRes, TableConfigVars>(TABLE_CONFIG, {
      variables: {
        templateId: parseInt(templateId, 10),
        context,
      },
    });
  }

  if (error) {
    return (
      <div>
        Error:
        {error.message}
      </div>
    );
  }

  const tableHeadersConfigFixed = tableConfigResponse?.data?.res?.headers?.fixed || [];
  const tableHeadersConfigVar = tableConfigResponse?.data?.res?.headers?.var || [];

  function computeGridColDef(element: ColumnType): GridColDef {
    return {
      field: `${element.id}`,
      headerName: `${t(element.id, element.id)}${element.uom && element.uom !== '' ? ` (${element.uom})` : ''}`,
      headerClassName: 'table-header__subtitle',
      headerAlign: 'left',
      align: 'left',
      minWidth: 250,
      flex: 1,
      sortable: true,
      filterable: false,
    };
  }

  const columnsOwnWidth = ['category', 'categories', 'tags'];

  const tableColumns = columns
    ? columns
      .map((col, i) => {
        if (linkNavigation && col.renderCell) {
          return (
            ({
              ...col,
              ...{
                renderCell: col?.renderCell ? (params: GridCellParams) => (
                  <Link
                    style={{
                      width: !columnsOwnWidth.includes(col.field)
                        ? '100%'
                        : 'fit-content',
                      padding: '0 16px',
                    }}
                    to={params?.row?.link}
                  >
                    {col.renderCell && col.renderCell(params)}
                  </Link>
                )
                  : (params: GridCellParams) => params.row.value,
                width: col.useTextLengthAsWidth && col.headerName
                  ? getHeaderWidthFromLength(col.headerName)
                  : localColumnsWidth[col.field] || col.minWidth || col.width,
                hide: scrollHorizontally
                  ? false :
                  col.hide
                    ? true
                    : visibleColumns
                      ? i >= visibleColumns
                      : false,
                filterable: col.filterable === false ? false : userCan({
                  section: 'filters',
                  type: 'view',
                  permission: 'page_view',
                })
                  ? true
                  : filterFieldsReader.includes(col.field),
              },
            })
          );
        }
        return (
          ({
            ...col,
            ...{
              width: col.useTextLengthAsWidth && col.headerName
                ? getHeaderWidthFromLength(col.headerName)
                : localColumnsWidth[col.field] || col.minWidth || col.width,
              hide: scrollHorizontally
                ? false
                : col.hide
                  ? true
                  : visibleColumns
                    ? i >= visibleColumns
                    : false,
              filterable: col.filterable === false ? false : userCan({
                section: 'filters',
                type: 'view',
                permission: 'page_view',
              }) ? true : filterFieldsReader.includes(col.field),
            },
          })
        );
      })

    : tableConfigResponse?.data?.res
      ? (tableHeadersConfigFixed.length > 0 || tableHeadersConfigVar.length > 0) && [
        ...(tableHeadersConfigFixed?.reduce((acc, curr) => {
          acc.push(computeGridColDef(curr));
          return acc;
        }, [] as GridColDef[]) || []),
        ...(tableHeadersConfigVar?.reduce((acc, curr) => {
          acc.push(computeGridColDef(curr));
          return acc;
        }, [] as GridColDef[]) || []),
      ]
      : [];

  function filterItemEqualsTo(m: GridFilterItem, field: string, operator: string) {
    return m.columnField === field && m.operatorValue === operator;
  }

  const handleColumnWidthChange = (data: GridColumnResizeParams) => {
    setLocalColumnsWidth((prev) => ({
      ...prev,
      ...{
        [data.colDef.field]: data.colDef.computedWidth,
      },
    }));
  };

  const handleOnRowClick = (params: GridRowParams) => {
    if (onRowClick) {
      onRowClick(params);
    } else if (rowClickLink && params.id) {
      navigate(formatLink(params.id) || '');
    }
  };

  const CustomToolbarWithProps = useCallback(() => (
    <CustomToolbar
      resetFn={onResetClick
        ? () => {
          handleResetAll();
          onResetClick();
        }
        : undefined}
      enableReset={!!localFiltersModel?.items.find((f) => !!f.value)}
      user={user}
      enableExport={enableExport}
    />
  ), [localFiltersModel]);

  const CustomPaginationWithProps = useCallback(() => (
    <CustomPagination
      options={options}
      setOptions={setOptions}
      onOptionsChange={onOptionsChange}
      lastPage={Math.floor(totalRows / options.rowsPerPage)}
    />
  ), [options.rowsPerPage, options.page, totalRows]);

  return (
    <>
      {tableTitle && <TitleView title={tableTitle} align={tableTitleAlignment} smallMargin={tableTitleSmallMargin} />}
      <TeamCheckWrapper teamId={teamId} dataTeamId={nextTeamId}>
        <XGrid
          localeText={{
            MuiTablePagination: {
              labelRowsPerPage: t('table.rowsPerPage', 'rows per page'),
            },
            filterPanelOperators: t('table.operators', 'operators'),
            filterPanelColumns: t('table.columns', 'columns'),
            filterPanelAddFilter: t('table.addFilter', 'add filter'),
            footerTotalRows: footerTotalText || t('table.totalRows', 'total rows:'),
            toolbarFilters: t('table.filters', 'filters'),
            toolbarColumns: t('table.columns', 'columns'),
            noRowsLabel: t('table.noRows', 'No rows'),
            toolbarExport: 'export',
            toolbarExportCSV: 'download as CSV',
          }}
          ref={tableRef}
          className={`table__default ${className || ''} ${scrollHorizontally ? 'table__scroll-horizontally' : ''}`}
          loading={loading}
          columns={tableColumns || []}
          headerHeight={compactHeader ? 24 : 52}
          disableColumnResize={disableColumnResize}
          autoHeight
          disableColumnMenu
          disableColumnSelector={!enableColumnSelector}
          disableColumnFilter={disableColumnFilter}
          disableColumnReorder={disableColumnReorder}
          disableDensitySelector
          disableMultipleColumnsSorting
          disableMultipleSelection
          disableSelectionOnClick
          getRowId={getRowId}
          filterMode={TABLE_FILTER_MODE}
          sortingMode={clientSorting ? 'client' : TABLE_SORTING_MODE}
          components={{
            ...(!hideTopFilters ? {
              Toolbar: CustomToolbarWithProps,
            } : {}),
            ...(!hideFooter ? {
              Footer: customFooter || TableFooter,
            } : {}),
            Pagination: CustomPaginationWithProps,
            ...components,
          }}
          pageSize={options.rowsPerPage}
          page={options.page}
          paginationMode={TABLE_PAGINATION_MODE}
          filterModel={localFiltersModel}
          onFilterModelChange={
          onFilterModelChange
            ? handleFilterModelChange
            : ((model) => {
              if (setFilters) {
                const startDate = model.items.filter((m) => filterItemEqualsTo(m, 'date', 'after'));
                const endDate = model.items.filter((m) => filterItemEqualsTo(m, 'date', 'before'));
                const name = model.items.filter((m) => filterItemEqualsTo(m, 'athlete', 'contain'));
                setFilters({
                  ...(!!startDate && startDate[0]?.value
                    ? { startDate: new Date(startDate[0].value) }
                    : { startDate: undefined }),
                  ...(!!endDate && endDate[0]?.value
                    ? { endDate: new Date(endDate[0].value) }
                    : { endDate: undefined }
                  ),
                  ...(name ? { athleteIds: name.filter((n) => !!n.value).map((n) => n.value) } : { athleteIds: [] }),
                });
              }
            })
        }
          rows={rows}
          rowHeight={compactRows ? 28 : 39}
          rowCount={totalRows}
          rowsPerPageOptions={rowsPerPageOptions || DEFAULT_ROWS_PER_PAGE_OPTIONS}
          getRowClassName={(params) => {
            let out = `font-roboto ${disableCursor ? '' : 'cursor-pointer'}`;
            if (getRowClassName) {
              out += ` ${getRowClassName(params)}`;
            }
            return out;
          }}
          onPageChange={(page) => handleOptionsChange({ page })}
          onRowClick={handleOnRowClick}
          onPageSizeChange={(size) => handleOptionsChange({ rowsPerPage: size })}
          onResize={() => setResizeMonitor(resizeMonitor + 1)}
          pagination={!loading && (totalRows ? totalRows > DEFAULT_ROWS_PER_PAGE_OPTIONS[0] : false)}
          hideFooter={hideFooter}
          sortModel={sortModel}
          sortingOrder={['asc', 'desc']}
          onSortModelChange={(model, details) => {
            if (!clientSorting && JSON.stringify(model) !== JSON.stringify(sortModel)) {
              handleSortModelChange(model, details);
            }
          }}
          checkboxSelection={checkboxSelection}
          onSelectionModelChange={onSelectionModelChange}
          selectionModel={selectionModel}
          onColumnWidthChange={handleColumnWidthChange}
        />
      </TeamCheckWrapper>
    </>
  );
}
