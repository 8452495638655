import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmIcon from '@images/icons/svg/confirm.svg';
import Modal from './form/Modal';

type PropsIn = {
  title: string;
  content?: string | React.ReactElement;
  buttonCancel?: string;
  buttonAction?:string;
  buttonColor?: 'inherit' | 'default' | 'primary' | 'secondary' | 'danger';
  visible: boolean;
  onClick?: () => void;
  onClose: () => void;
  enabledAction?: boolean;
  children?: React.ReactNode;
  buttons?: React.ReactNode;
  customIcon?: React.ElementType<any> | React.ReactElement;
}

export default function DialogBox(props: PropsIn) {
  const {
    title, content, buttonAction, buttonColor, visible, onClick, onClose, enabledAction, children, buttons, customIcon, buttonCancel,
  } = props;

  const { t } = useTranslation();

  return (
    <Modal
      title={title}
      buttonColor={buttonColor || 'primary'}
      buttonCancel={buttonCancel}
      buttonAction={!buttons ? buttonAction || t('dialog.action.confirm', 'confirm') : undefined}
      icon={!customIcon ? <ConfirmIcon /> : customIcon}
      disabledButton={enabledAction !== undefined ? !enabledAction : undefined}
      isValidation={enabledAction !== undefined ? enabledAction : true}
      content={content}
      visible={visible}
      onClick={onClick}
      onClose={onClose}
      buttons={buttons}
    >
      {children}
    </Modal>
  );
}
