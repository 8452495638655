import * as React from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  CircularProgress, createStyles, Typography,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import grey from '@material-ui/core/colors/grey';
import BaseChip from 'components/form/BaseChip';

interface PropsIn {
  open: boolean;
  setOpen?: (status: boolean) => void;
  anchor?: 'left' | 'right';
  children: React.ReactNode;
  size?: 'small' | 'large';
  title?: string;
  counter?: number;
  actionsLeft?: React.ReactNode[];
  actionsRight?: React.ReactNode[];
  isLoading?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  customClasses?: string;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  small: {
    width: `${Math.floor(theme.breakpoints.values.sm * 0.75)}px`,
  },
  medium: {
    width: `${Math.floor(theme.breakpoints.values.md * 0.6)}px`,
  },
  large: {
    width: `${Math.floor(theme.breakpoints.values.md * 0.75)}px`,
  },
  extraLarge: {
    width: `${Math.floor(theme.breakpoints.values.lg * 0.7)}px`,
  },
}));

export function SideDrawer(props: PropsIn) {
  const {
    actionsLeft,
    actionsRight,
    anchor,
    children,
    isLoading,
    open,
    onOpen,
    onClose,
    setOpen,
    size,
    title,
    counter,
    customClasses,
  } = props;
  const classes = useStyles();

  return (
    <SwipeableDrawer
      classes={{
        paper: classes[size || 'large'],
      }}
      className={`${isLoading ? 'is-loading' : ''} ${customClasses} drawer-wrapper`}
      anchor={anchor || 'right'}
      open={open}
      transitionDuration={{
        enter: 300,
        exit: 300,
      }}
      swipeAreaWidth={0}
      onClose={() => {
        if (onClose) {
          onClose();
        }
      }}
      onOpen={() => {
        if (setOpen) {
          setOpen(true);
        }
        if (onOpen) {
          onOpen();
        }
      }}
      onClick={(e) => e.stopPropagation()}
      onKeyDown={(e) => {
        if (e.key === 'Escape' && setOpen) {
          setOpen(false);
        }
      }}
    >
      {title && (
        <Box className="drawer-title" boxShadow={3} mb={3} px={2} py={1} display="flex" height={52}>
          <Typography variant="h4" component="h4" style={{ width: '100%' }}>
            {title}
          </Typography>
          {counter ? <BaseChip className="chip-counter-header" size="small" label={`${counter} KPI`} /> : null}
        </Box>
      )}

      <Box py={1} px={2} className="sidedrawer__wrapper">
        <Box className="sidedrawer__content">{children}</Box>
        {isLoading && (
          <Box position="absolute" left="50%" top="50%" style={{ marginLeft: -25, marginTop: -25 }}>
            <CircularProgress />
          </Box>
        )}
      </Box>

      {(actionsLeft || actionsRight || isLoading) && (
        <Box
          className={`${isLoading ? 'mouse-events-none' : ''} drawer-actions`}
          mt={3}
          py={1}
          position="sticky"
          bottom={0}
          width="100%"
          bgcolor={grey[200]}
          justifyContent="space-between"
          display="flex"
        >
          <Box style={{ paddingLeft: '16px', opacity: isLoading ? 0.5 : 1 }}>{actionsLeft}</Box>
          <Box style={{ paddingRight: '16px', opacity: isLoading ? 0.5 : 1 }}>{actionsRight}</Box>
        </Box>
      )}
    </SwipeableDrawer>
  );
}
