import { GroundType } from 'models/ground';
import React from 'react';
import { Group, Line } from 'react-konva';
import {
  DEFAULT_PITCH_LINE_COLOR,
  DEFAULT_PITCH_STROKEWIDTH,
} from '../constants';
import {
  rotate, flipPoint, computeAngle,
} from '../utils';
import Vertices from '../Vertices';

export default function Fencing(props: { groundData: GroundType; scale: number; vertices: boolean }) {
  const { groundData, scale, vertices } = props;

  let vertexA = {
    x:
      rotate(flipPoint({ x: groundData.vertexAX?.value, y: groundData.vertexAY?.value }, groundData), computeAngle(groundData)).x
      * scale,
    y:
      rotate(flipPoint({ x: groundData.vertexAX?.value, y: groundData.vertexAY?.value }, groundData), computeAngle(groundData)).y
      * scale,
  };
  let vertexC = {
    x:
      rotate(flipPoint({ x: groundData.vertexCX?.value, y: groundData.vertexCY?.value }, groundData), computeAngle(groundData)).x
      * scale,
    y:
      rotate(flipPoint({ x: groundData.vertexCX?.value, y: groundData.vertexCY?.value }, groundData), computeAngle(groundData)).y
      * scale,
  };

  if (vertexA.x > vertexC.x) {
    const swap = vertexC;
    vertexC = vertexA;
    vertexA = swap;
  }

  const width = vertexC.x - vertexA.x;
  const widthInMeters = groundData.vertexCX?.value;
  const m = widthInMeters ? width / widthInMeters : 0;
  const enGardeOffset = 2 * m;
  const warningZoneStartOffset = enGardeOffset + 3 * m;
  const warningZoneEndOffset = warningZoneStartOffset + 2 * m;

  return (
    <Group>
      {vertices ? <Vertices A={vertexA} C={vertexC} /> : null}
      {/* border */}
      <Line
        fill={groundData.groundSurfaceColor ? groundData.groundSurfaceColor : '#00000000'}
        points={[vertexA.x, vertexA.y, vertexC.x, vertexA.y, vertexC.x, vertexC.y, vertexA.x, vertexC.y]}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
        closed
      />
      {/* warning zone end */}
      <Line
        points={[vertexA.x + width / 2 - warningZoneEndOffset, vertexA.y, vertexC.x - width / 2 - warningZoneEndOffset, vertexC.y]}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      {/* warning zone start */}
      <Line
        points={[vertexA.x + width / 2 - warningZoneStartOffset, vertexA.y, vertexC.x - width / 2 - warningZoneStartOffset, vertexC.y]}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      {/* en-garde */}
      <Line
        points={[vertexA.x + width / 2 - enGardeOffset, vertexA.y, vertexC.x - width / 2 - enGardeOffset, vertexC.y]}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      {/* center */}
      <Line
        points={[vertexA.x + width / 2, vertexA.y - 0.3 * m, vertexC.x - width / 2, vertexC.y + 0.3 * m]}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      {/* en-garde */}
      <Line
        points={[vertexA.x + width / 2 + enGardeOffset, vertexA.y, vertexC.x - width / 2 + enGardeOffset, vertexC.y]}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      {/* warning zone start */}
      <Line
        points={[vertexA.x + width / 2 + warningZoneStartOffset, vertexA.y, vertexC.x - width / 2 + warningZoneStartOffset, vertexC.y]}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
      {/* warning zone end */}
      <Line
        points={[vertexA.x + width / 2 + warningZoneEndOffset, vertexA.y, vertexC.x - width / 2 + warningZoneEndOffset, vertexC.y]}
        stroke={DEFAULT_PITCH_LINE_COLOR}
        strokeWidth={DEFAULT_PITCH_STROKEWIDTH}
      />
    </Group>
  );
}
