import React, {
  useEffect, useState,
} from 'react';
import { Box } from '@material-ui/core';
import { CACHE_AND_NETWORK } from 'lib/cache';
import { useLazyQueryCached } from 'components/utils/graphql';
import * as HighCharts from 'highcharts';
import { MultiLineChart } from '../charts/MultiLineChart';
import SelectSeries from './SelectSeries';
import SelectEvents from './SelectEvents';
import { SelectZones } from './SelectZones';
import {
  ATHLETE_DATA, AthleteDataRes, AthleteDataVars, LayoutType,
} from '../../query/track';
import { globalEventsChannel } from '../../lib/events';
import { TrackExportDataType } from './View';

import Moment from "moment";
import MomentTimeZone from "moment-timezone";

window.moment = Moment
MomentTimeZone();

interface PropsIn {
  teamId: string;
  templateId: string;
  trackId?: string;
  athletesessionId?: string;
  currentDrill: number;
  layout?: LayoutType[];
  plotLinesY?: HighCharts.YAxisPlotLinesOptions[];
  // onMouseMove?: (x: number) => void;
  setActiveSeries?: React.Dispatch<React.SetStateAction<TrackExportDataType>>,
  setTrackExportData: (state) => TrackExportDataType;
}

export default function TrackSeriesChart(props: PropsIn) {
  const {
    teamId, templateId, trackId, athletesessionId, layout, // onMouseMove,
    plotLinesY, setActiveSeries, currentDrill, setTrackExportData,
  } = props;
  const layoutPresets = layout
    ?.find((d) => d.key === 'main_chart')
    ?.value?.reduce((acc: { [k: string]: string }, cur) => {
      acc[cur.key] = cur.value;
      return acc;
    }, {}) || {};

  const [series, setSeries] = useState<string[]>(layoutPresets?.default_series?.split(',') || []);
  const [events, setEvents] = useState<string[]>(layoutPresets?.default_events?.split(',') || []);
  const [zones, setZones] = useState<string[]>([]);

  const [fetchData, { loading, error, data }] = useLazyQueryCached<AthleteDataRes, AthleteDataVars>(ATHLETE_DATA(trackId ? 'track' : 'session'), {
    variables: trackId
      ? {
        trackId,
      }
      : {
        id: athletesessionId,
      },
    ...CACHE_AND_NETWORK,
  });

  useEffect(() => {
    setTimeout(() => {
      fetchData()
    }, 1000)
  }, []);

  useEffect(() => {
    if (setActiveSeries) {
      const activeSeries = `${series.join(',')},${events.join(',')}`.replace(/,+$/, ''); // remove ',' at the end of string if not events
      setActiveSeries((prevState) => ({
        ...prevState,
        active_series: activeSeries || '',
      }));
    }
  }, [series, layoutPresets?.default_series, layoutPresets?.default_events, events]);

  useEffect(() => {
    if (data?.res.track?.team?.id && data.res.track.team.id !== teamId) {
      globalEventsChannel.emit('onTeamChange', data.res.track.team.id);
    }
  }, [data]);

  return (
    <Box className="track-series__multiline">
      <Box display="flex" className="track-series__selects" marginBottom="2px">
        <SelectSeries
          trackId={trackId}
          athleteSessionId={data?.res?.id}
          values={series}
          onChange={(s) => setSeries(s)}
          preset={layoutPresets?.default_series}
        />
        <SelectEvents
          trackId={trackId}
          athleteSessionId={data?.res?.id}
          preset={events}
          className="track-series__events"
          events={events}
          onChange={(s) => setEvents(s)}
        />
        <SelectZones
          athletesessionId={athletesessionId}
          className="track-series__zones"
          onChange={(s) => setZones(s)}
        />
      </Box>
      {!loading && !error && data?.res?.id && (
        <MultiLineChart
          athleteSessionId={athletesessionId}
          currentDrill={currentDrill}
          series={series}
          events={events}
          zones={zones}
          templateId={templateId}
          updateSeries={setSeries}
          setTrackExportData={setTrackExportData}
          setActiveSeries={setActiveSeries}
          plotLinesY={series.includes('power_aer') && plotLinesY || []}
        />
      )}
    </Box>
  );
}
