import React, {
  useState, useEffect, useContext, useRef,
} from 'react';
import {useMutation, useLazyQuery} from '@apollo/client';
import { SideDrawer } from 'components/layout/SideDrawer';
import BaseButton from 'components/form/BaseButton';
import {Box, Tooltip} from '@material-ui/core';
import CancelIcon from '@images/IconHeader/cancel.svg';
import { Column } from 'components/layout/Column';
import { Row } from 'components/layout/Row';
import { useTranslation } from 'react-i18next';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { TextField } from 'components/form/TextField';
import { CREATE_OR_UPDATE_MY_STATS } from 'mutation/stats';
import { useSnackbar } from 'notistack';
import {MY_STATS_LIMIT, SNACKBAR_TIMEOUT} from 'components/constants';
import { MyStatsContext } from 'Authenticator';
import Checkbox from 'components/form/Checkbox';
import { statsProperties } from './SeriesInputs2';
import {MY_STATS_COUNT} from "../../query/stats";

type PropsIn = {
    statsSessionId: string;
    openAddToMyStats: boolean;
    setOpenAddToMyStats: (b:boolean) => void;
    properties: statsProperties;
    setProperties: (s: statsProperties) => void
    orderBy?: string;
    teamId?: string;
}

export default function AddToMyStatsDrawer(props: PropsIn) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    statsSessionId, openAddToMyStats, setOpenAddToMyStats, properties, setProperties, orderBy, teamId
  } = props;

  const [name, setName] = useState<string|undefined>('');
  const [showInMyStats, setShowInMyStats] = useState(false);
  const [showInDashboard, setShowInDashboard] = useState(false);
  const { setMyStats } = useContext(MyStatsContext);
  const editValidation = (showInDashboard || showInMyStats) ? !!name : true;
  const [disableShowInMyStats, setDisableShowInMyStats] = useState(false);

  const [fetchMyStats] = useLazyQuery(MY_STATS_COUNT, {
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      setDisableShowInMyStats(data?.res?.myStatsCount > MY_STATS_LIMIT);
    },
  });

  useEffect(() => {
    fetchMyStats({
      variables: {
        teamId,
      },
    });
  }, []);

  const [createOrUpdateMyStats] = useMutation(CREATE_OR_UPDATE_MY_STATS, {
    onError: () => enqueueSnackbar(t('dialog.general.somethingWentWrong', 'something went wrong'), {
      variant: 'error',
      autoHideDuration: SNACKBAR_TIMEOUT,
    }),
    onCompleted: () => {
      enqueueSnackbar(t('stats.dialog.propertiesSuccessfullyUpdated', 'properties successfully updated'), {
        variant: 'success',
        autoHideDuration: SNACKBAR_TIMEOUT,
      });
      setMyStats((prev) => ({
        ...prev,
        refetchMyStatsStatus: prev.refetchMyStatsStatus + 1,
      }));
    },
  });

  useEffect(() => {
    setName(properties.title);
    setShowInMyStats(properties.showMyStats);
    setShowInDashboard(properties.showDashboard);
  }, [properties]);

  useEffect(() => {
    if (showInDashboard && !disableShowInMyStats) {
      setShowInMyStats(true);
    }
  }, [showInDashboard]);

  return (
    <SideDrawer
      size="small"
      title={t('stats.drawer.editProperties', 'edit properties')}
      open={openAddToMyStats}
      setOpen={setOpenAddToMyStats}
      actionsLeft={[
        <BaseButton
          key="cancel"
          startIcon={<CancelIcon />}
          onClick={() => {
            setOpenAddToMyStats(false);
          }}
          noMargins
        >
          {t('stats.drawer.button.cancel', 'cancel')}
        </BaseButton>,
      ]}
      actionsRight={[
        <BaseButton
          key="save"
          disabled={!editValidation}
          color="primary"
          marginRight
          isValidation={editValidation}
          onClick={() => {
            createOrUpdateMyStats({
              variables:
              {
                statsSessionId,
                myStatsName: name,
                showMyStats: showInMyStats,
                showDashboard: showInDashboard,
                orderBy,
              },
            });
            setOpenAddToMyStats(false);
            setProperties({ title: name, showMyStats: showInMyStats, showDashboard: showInDashboard });
          }}
          startIcon={<SaveAltIcon />}
          noMargins
        >
          {t('drawer.actions.save', 'save')}
        </BaseButton>,
      ]}
    >
      <Box>
        <Column xs={12}>
          <Row>
            <Column xs={12}>
              <TextField
                required
                label={t('stats.labels.statName', 'stat name')}
                value={name}
                inputProps={{ maxLength: 50 }}
                fullWidth
                onChange={(e) => setName(e.target.value)}
              />
            </Column>
          </Row>
          <Row>
            <Column xs={12}>
              <Tooltip
                title={t('stats.drawer.tooltipMessage', 'You have reached the maximum number of stats saved')}
                arrow
                disableHoverListener={!disableShowInMyStats}
              >
                <span>
                  <Checkbox
                    disabled={disableShowInMyStats}
                    readonly={showInDashboard}
                    value={showInMyStats}
                    label={t('stats.drawer.saveInMyStats', 'save in my stats')}
                    onClick={() => setShowInMyStats((prev) => !prev)}
                  />
                </span>
              </Tooltip>
            </Column>
          </Row>
          <Row>
            <Column xs={12}>
              <Checkbox
                value={showInDashboard}
                label={t('stats.labels.addToDashboard', 'add to dashboard')}
                onClick={() => setShowInDashboard((prev) => !prev)}
              />
            </Column>
          </Row>
        </Column>
      </Box>
    </SideDrawer>
  );
}
