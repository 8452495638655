import WebPlayerNew from './WebPlayer';
import React from 'react';
import { useQueryParams } from '../utils/hooks';

/**
 * Componente `FullscreenWebplayer`
 *
 * Questo componente visualizza il lettore WebPlayerNew in modalità fullscreen.
 * Utilizza i parametri della query string dell'URL per configurare le proprietà
 * del componente WebPlayerNew.
 *
 * I parametri accettati tramite la query string includono:
 * - `drill`: l'identificativo del drill attuale (facoltativo).
 * - `sessionId`: l'identificativo della sessione (facoltativo).
 * - `teamId`: l'identificativo del team (facoltativo).
 * - `templateId`: l'identificativo del template attuale (facoltativo).
 *
 * Il componente `useQueryParams` viene utilizzato per leggere e gestire i parametri
 * della query string dell'URL.
 *
 * @returns {JSX.Element} Il componente FullscreenWebplayer.
 */
const FullscreenWebplayer = (): JSX.Element => {
  const [queryParams] = useQueryParams();

  return <div className="fullscreen-webplayer">
    <WebPlayerNew
      currentDrill={queryParams.drill ? parseInt(queryParams.drill, 10) : null}
      sessionId={queryParams.sessionId}
      teamId={queryParams.teamId}
      templateId={queryParams.templateId}
      isFullscreen
    />
  </div>;
};

export default FullscreenWebplayer;
