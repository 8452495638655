import { GridCellValue, GridColumnHeaderParams } from '@material-ui/x-grid';
import { KPIType } from 'query/track';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { kpiLabelExtractor } from './utils/kpiLabelExtractor';
import { getUomTranslation, timeToMilliseconds } from './utils/utils';

function getKpiTableHeaderNicer(params: GridColumnHeaderParams, kpi: KPIType, teamId: string) {
  const { t } = useTranslation();
  return (
    <div className="MuiDataGrid-columnHeaderTitle kpi-table-header">
      <span>{kpiLabelExtractor(teamId, kpi.name) || '-'}</span>
      <span className="table-header-unit">
        {getUomTranslation(t, kpi.name === 'eccentric_index' ? '%' : kpi.uom)}
      </span>
    </div>
  );
}

function getKpiTable(kpi: KPIType, teamId: string) {
  return (
   kpiLabelExtractor(teamId, kpi.name) 
  );
}

function getUomTable(kpi: KPIType, t) {
  return (
    getUomTranslation(t, kpi.name === 'eccentric_index' ? '%' : kpi.uom)
  );
}

function getGenericTableHeaderNicer(params: GridColumnHeaderParams, name: string, unit?: string) {
  const { t } = useTranslation();
  return (
    <div className="MuiDataGrid-columnHeaderTitle generic-table-header">
      <span>{name}</span>
      <span className="table-header-unit">
        {getUomTranslation(t, unit)}
      </span>
    </div>
  );
}

function sortComparatorKpi(a: GridCellValue, b: GridCellValue, name = '') {
  const aCell = ReactDOMServer.renderToString(a);
  const bCell = ReactDOMServer.renderToString(b);
  const element = document.createElement('div');
  element.innerHTML = aCell;
  const aValue = element?.querySelectorAll('.kpi-value')?.length ? element?.querySelectorAll('.kpi-value')[0].innerHTML : null;
  element.innerHTML = bCell;
  const bValue =  element?.querySelectorAll('.kpi-value')?.length ? element?.querySelectorAll('.kpi-value')[0].innerHTML : null;
  if (name === 'duration') {
    return timeToMilliseconds(aValue) > timeToMilliseconds(bValue) ? -1 : timeToMilliseconds(aValue) < timeToMilliseconds(bValue) ? 1 : 0;
  }
  return Number(aValue) > Number(bValue) ? -1 : 1;
}

export { getKpiTableHeaderNicer, getUomTable, getKpiTable, getGenericTableHeaderNicer, sortComparatorKpi };
