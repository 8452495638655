import React, {
  Suspense, useContext, useEffect,
} from 'react';
import {
  Routes, Route, Navigate, useLocation,
} from 'react-router-dom';
import { useNavigate, useParams } from 'react-router';
import { MeType } from 'models/me';
import { Helmet } from 'react-helmet';
import TestComponents from './TestComponents';
import MainLoader from './layout/MainLoader';
import { KPIType } from '../query/track';
import { withMe } from './MeUserWrapper';
import ACLWrapper, { UserContext } from './ACLWrapper';
import TestComponentsCurrent from './TestComponentsCurrent';
import TestGroundsPlayer from './TestGroundsPlayer';
import TestComponentsNewPlayer from './TestComponentsNewPlayer';
import FullscreenWebplayer from './webPlayerNew/FullscreenWebplayer';
import { googleAnalyticsId } from '../App';
import ErrorGuard from './layout/ErrorGuard';

const ClubLockedView = React.lazy(() => import('components/ClubLockedView'));
const WelcomeOnBoardView = React.lazy(() => import('components/welcome/View'));
const DashboardView = React.lazy(() => import('components/dashboard/View'));
const CalendarView = React.lazy(() => import('components/calendar/View'));
const TracksView = React.lazy(() => import('components/tracks/View'));
const TrackView = React.lazy(() => import('components/track/View'));
const SessionsFormView = React.lazy(() => import('components/sessions/FormView'));
const SessionsView = React.lazy(() => import('components/sessions/View'));
const SessionDetailsView = React.lazy(() => import('components/sessions/DetailsView'));
const FilesView = React.lazy(() => import('components/files/View'));
const AthletesView = React.lazy(() => import('components/athletes/View'));
const AthleteView = React.lazy(() => import('components/athlete/View'));
const UsersView = React.lazy(() => import('components/users/View'));
const UserView = React.lazy(() => import('components/user/View'));
const TeamsView = React.lazy(() => import('components/team/View'));
const TeamDetails = React.lazy(() => import('components/team/TeamDetailsView'));
const AthleteSessionsView = React.lazy(() => import('components/athletesessions/View'));
const ComparisonView = React.lazy(() => import('components/comparison/View'));
const TargetsView = React.lazy(() => import('components/targets/View'));
const TargetDetailsView = React.lazy(() => import('components/targets/TargetDetailsView'));
const TemplatesView = React.lazy(() => import('components/templates/View'));
const DetailTemplateView = React.lazy(() => import('components/templates/DetailsTemplateView'));
const AthleteSessionView = React.lazy(() => import('components/athletesessions/AthSessionView'));
const RpeView = React.lazy(() => import('components/athletesessions/RpeView'));
const ASPView = React.lazy(() => import('components/performance/ASPListView'));
const ASPSingleView = React.lazy(() => import('components/performance/ASPDetailsView'));
const MPPView = React.lazy(() => import('components/performance/MPPListView'));
const MPPSingleView = React.lazy(() => import('components/performance/MPPDetailsView'));
const ClubView = React.lazy(() => import('components/club/View'));
const GroundsView = React.lazy(() => import('components/ground/View'));
const GroundDetailsView = React.lazy(() => import('components/ground/GroundDetailsView'));
const TrendView = React.lazy(() => import('components/stats/TrendView'));
const SummaryView = React.lazy(() => import('components/stats/SummaryView'));
const RadarView = React.lazy(() => import('components/stats/RadarView'));
const StatsRedirectView = React.lazy(() => import('components/stats/StatsRedirect'));
const ExportsView = React.lazy(() => import('components/ExportsView'));
const ChangelogView = React.lazy(() => import('components/ChangelogView'));
const MyStatsView = React.lazy(() => import('components/stats/MyStatsView'));
const Page404 = React.lazy(() => import('components/Page404'));
const Login = React.lazy(() => import('../Login'));
const ClearCacheView = React.lazy(() => import('../components/ClearCacheView'));

export const titlePrefix = 'gpexe';

type RoutesPropsIn = {
  me: MeType;
  lastTeamId?: string | number;
  templateId?: string | number;
  kpi?: KPIType[] | undefined;
  id: string | number;
  clubId?: string | number;
  hasRpeLastTeam?: boolean
};

declare global {
  interface Window {
    gtag?: any
  }
}

const isDev = process.env.NODE_ENV === 'development';

function RouteElement({ component, title, ...rest }) {
  const params = useParams();
  const location = useLocation();

  const Component = component;

  useEffect(() => {
    const gaData = {
      page_path: location.pathname + location.search + location.hash,
      page_search: location.search,
      page_hash: location.hash,
      page_teamId: params.teamId || rest.teamId,
      page_templateId: params.templateId || rest.templateId,
      page_trackId: params.trackId || rest.trackId,
      page_athleteId: params.athleteId || rest.athleteId,
      page_sessionId: params.sessionId || rest.sessionId,
      page_athleteSessionId: params.athleteSessionId || rest.athleteSessionId,
      page_rpeId: params.rpeId || rest.rpeId,
      page_userId: params.userId || rest.userId,
    };

    if (googleAnalyticsId && window.gtag) {
      window.gtag('event', 'page_view', gaData);
    } else if (isDev) {
      console.groupCollapsed(' - GA - DEBUG');
      console.table(gaData);
      console.groupEnd();
    }

    document.body.classList.remove('team-different');
  }, [location.pathname]);

  return (
    <>
      <Helmet>
        <title>{`${titlePrefix}${title ? ` - ${title}` : ''}`}</title>
      </Helmet>

      <ErrorGuard>
        <Component
          teamId={params.teamId}
          templateId={params.templateId}
          trackId={params.trackId}
          athleteId={params.athleteId}
          sessionId={params.sessionId}
          athleteSessionId={params.athleteSessionId}
          rpeId={params.rpeId}
          userId={params.userId}
          id={params.id}
          {...rest}
        />
      </ErrorGuard>
    </>
  );
}

function AppRoutes(propi: RoutesPropsIn) {
  const {
    me, lastTeamId, templateId, kpi,
    id, clubId, hasRpeLastTeam,
  } = propi;

  const { userCan, user } = useContext(UserContext);
  const isClubLocked = me?.lastTeam?.club?.locked;
  const navigate = useNavigate();

  if (user === null) {
    navigate('/login');
  }

  return (
    !isClubLocked ? (
      <Suspense fallback={<MainLoader />}>
        <Routes>
          <Route
            key="/welcome"
            path="/welcome"
            element={(
              <RouteElement
                component={WelcomeOnBoardView}
                teamId={lastTeamId}
                userId={id}
                me={me}
                title="welcome on board"
              />
              )}
          />

          <Route
            key="/clear"
            path="/clear"
            element={(
              <RouteElement
                component={ClearCacheView}
                me={me}
                title="clear cache"
              />
              )}
          />

          <Route
            key="/dashboard"
            path="/dashboard"
            element={(
              <ACLWrapper section="dashboard" type="view" permission="page_view">
                <RouteElement
                  component={DashboardView}
                  teamId={lastTeamId}
                  userId={id}
                  title="dashboard"
                />
              </ACLWrapper>
            )}
          />

          <Route
            path="/calendar"
            element={(
              <ACLWrapper section="calendar" type="view" permission="page_view">
                <RouteElement
                  component={CalendarView}
                  teamId={lastTeamId}
                  userId={id}
                  templateId={templateId}
                  title="calendar"
                />
              </ACLWrapper>
              )}
          />
          <Route
            path="/athletes/:athleteId"
            element={(
              <RouteElement
                component={AthleteView}
                teamId={lastTeamId}
                userId={id}
                templateId={templateId}
                title="athlete"
              />
              )}
          />
          <Route
            path="/athletes"
            element={(
              <RouteElement
                component={AthletesView}
                teamId={lastTeamId}
                userId={id}
                title="athletes"
              />
              )}
          />
          <Route
            path="/tracks"
            element={(
              <RouteElement
                component={TracksView}
                teamId={lastTeamId}
                userId={id}
                templateId={templateId}
                kpi={kpi}
                title="tracks"
              />
              )}
          />
          <Route
            path="/tracks/:trackId"
            element={(
              <RouteElement
                component={TrackView}
                teamId={lastTeamId}
                userId={id}
                templateId={templateId}
                title="track"
                clubId={me?.lastTeam?.club?.id}
              />
              )}
          />
          {!me?.lastTeam?.locked
              && (
                <Route
                  path="/sessions/new"
                  element={(
                    <ACLWrapper section="sessions" type="actions" permission="create_session">
                      <RouteElement
                        component={SessionsFormView}
                        teamId={lastTeamId}
                        userId={id}
                        templateId={templateId}
                        title="session - new"
                      />
                    </ACLWrapper>
                  )}
                />
              )}
          {!me?.lastTeam?.locked
              && (
                <Route
                  path="/sessions/:sessionId/edit"
                  element={(
                    <ACLWrapper section="sessions" type="actions" permission="update_session">
                      <RouteElement
                        component={SessionsFormView}
                        teamId={lastTeamId}
                        userId={id}
                        templateId={templateId}
                        isEdit
                        title="session - edit"
                      />
                    </ACLWrapper>
                  )}
                />
              )}
          <Route
            path="/sessions/:sessionId"
            element={(
              <ACLWrapper section="sessions" type="view" permission="page_view">
                <RouteElement
                  component={SessionDetailsView}
                  teamId={lastTeamId}
                  clubId={clubId}
                  userId={id}
                  templateId={templateId}
                  title="session"
                />
              </ACLWrapper>
              )}
          />
          <Route
            path="/sessions"
            element={(
              <ACLWrapper section="sessions" type="view" permission="page_view">
                <RouteElement
                  component={SessionsView}
                  teamId={lastTeamId}
                  templateId={templateId}
                  userId={id}
                  hasRpe={hasRpeLastTeam || false}
                  title="sessions"
                />
              </ACLWrapper>
              )}
          />

          <Route
            path="/files"
            element={(
              <ACLWrapper section="files" type="view" permission="page_view">
                <RouteElement
                  component={FilesView}
                  teamId={lastTeamId}
                  userId={id}
                  templateId={templateId}
                  title="files"
                />
              </ACLWrapper>
              )}
          />
          <Route
            path="/athlete_sessions"
            element={(
              <RouteElement
                component={AthleteSessionsView}
                teamId={lastTeamId}
                userId={id}
                templateId={templateId}
                kpi={kpi}
                hasRpe={hasRpeLastTeam || false}
                title="athlete sessions"
              />
              )}
          />
          <Route
            path="/comparison"
            element={(
              <ACLWrapper section="comparison" type="view" permission="page_view">
                <RouteElement
                  component={ComparisonView}
                  teamId={lastTeamId}
                  userId={id}
                  templateId={templateId}
                  title="comparison"
                />
              </ACLWrapper>
              )}
          />
          <Route
            path="/athlete_sessions/:athleteSessionId"
            element={(
              <RouteElement
                component={AthleteSessionView}
                teamId={lastTeamId}
                userId={id}
                templateId={templateId}
                title="athlete session"
              />
              )}
          />
          <Route
            path="/rpe/:rpeId"
            element={(
              <RouteElement
                component={RpeView}
                teamId={lastTeamId}
                userId={id}
                title="rpe"
              />
              )}
          />
          <Route
            path="/trend"
            element={(
              <ACLWrapper section="trend" type="view" permission="page_view">
                <RouteElement
                  component={TrendView}
                  teamId={lastTeamId}
                  userId={id}
                  title="trend"
                />
              </ACLWrapper>
              )}
          />
          <Route
            path="/summary"
            element={(
              <ACLWrapper section="summary" type="view" permission="page_view">
                <RouteElement
                  component={SummaryView}
                  teamId={lastTeamId}
                  userId={id}
                  title="summary"
                />
              </ACLWrapper>
              )}
          />

          <Route
            path="/radar"
            element={(
              <ACLWrapper section="radar" type="view" permission="page_view">
                <RouteElement
                  component={RadarView}
                  teamId={lastTeamId}
                  userId={id}
                  title="radar"
                />
              </ACLWrapper>
              )}
          />

          <Route
            path="/stats"
            element={(
              <ACLWrapper section="trend" type="view" permission="page_view">
                <RouteElement
                  component={StatsRedirectView}
                  title="stats"
                  userId={id}
                />
              </ACLWrapper>
              )}
          />
          {
              !me?.isLastTeamGk
              && (
                <>
                  <Route
                    path="/as_profiles/:id"
                    element={(
                      <ACLWrapper section="as_profiles" type="view" permission="page_view">
                        <RouteElement
                          component={ASPSingleView}
                          teamId={lastTeamId}
                          userId={id}
                          templateId={templateId}
                          title="as profile"
                        />
                      </ACLWrapper>
                    )}
                  />
                  <Route
                    path="/as_profiles"
                    element={(
                      <ACLWrapper section="as_profiles" type="view" permission="page_view">
                        <RouteElement
                          component={ASPView}
                          teamId={lastTeamId}
                          userId={id}
                          title="as profiles"
                        />
                      </ACLWrapper>
                    )}
                  />

                  <Route
                    path="/mp_profiles/:id"
                    element={(
                      <ACLWrapper section="mp_profiles" type="view" permission="page_view">
                        <RouteElement
                          component={MPPSingleView}
                          teamId={lastTeamId}
                          userId={id}
                          title="mp profile"
                        />
                      </ACLWrapper>
                    )}
                  />
                  <Route
                    path="/mp_profiles"
                    element={(
                      <ACLWrapper section="mp_profiles" type="view" permission="page_view">
                        <RouteElement
                          component={MPPView}
                          teamId={lastTeamId}
                          userId={id}
                          title="mp profiles"
                        />
                      </ACLWrapper>
                    )}
                  />
                </>
              )
            }
          <Route
            path="/targets"
            element={(
              <RouteElement
                component={TargetsView}
                teamId={lastTeamId}
                userId={id}
                templateId={templateId}
                title="targets"
              />
              )}
          />
          <Route
            path="/targets/:id"
            element={(
              <RouteElement
                component={TargetDetailsView}
                teamId={lastTeamId}
                userId={id}
                templateId={templateId}
                title="target"
              />
              )}
          />
          {/*        <Route
        path="/comparison"
        element={<div>Comparison</div>} /> */}
          <Route
            path="/templates"
            element={(
              <RouteElement
                component={TemplatesView}
                teamId={lastTeamId}
                userId={id}
                templateId={templateId}
                title="templates"
              />
              )}
          />

          <Route
            path="/templates/:id"
            element={(
              <RouteElement
                component={DetailTemplateView}
                teamId={lastTeamId}
                userId={id}
                templateId={templateId}
                title="template"
              />
              )}
          />
          <Route
            path="/teams"
            element={(
              <ACLWrapper section="teams" type="view" permission="page_view">
                <RouteElement
                  component={TeamsView}
                  title="teams"
                  teamId={lastTeamId}
                  userId={id}
                />
              </ACLWrapper>
              )}
          />
          <Route
            path="/teams/:teamId/"
            element={(
              <ACLWrapper section="teams" type="view" permission="page_view">
                <RouteElement
                  component={TeamDetails}
                  clubId={clubId}
                  userId={id}
                  title="team"
                />
              </ACLWrapper>
              )}
          />
          <Route
            path="/users"
            element={(
              <ACLWrapper section="users" type="view" permission="page_view">
                <RouteElement
                  component={UsersView}
                  teamId={lastTeamId}
                  userId={id}
                  title="users"
                />
              </ACLWrapper>
              )}
          />
          <Route
            path="/users/:userId"
            element={(
              <ACLWrapper section="users" type="view" permission="page_view">
                <RouteElement
                  component={UserView}
                  me={me}
                  teamId={lastTeamId}
                  clubId={clubId}
                  title="user"
                />
              </ACLWrapper>
              )}
          />
          <Route
            path="/grounds"
            element={(
              <ACLWrapper section="grounds" type="view" permission="page_view">
                <RouteElement
                  component={GroundsView}
                  teamId={lastTeamId}
                  userId={id}
                  templateId={templateId}
                  title="grounds"
                />
              </ACLWrapper>
              )}
          />

          <Route
            path="/grounds/:id"
            element={(
              <ACLWrapper section="grounds" type="view" permission="page_view">
                <RouteElement
                  component={GroundDetailsView}
                  teamId={lastTeamId}
                  userId={id}
                  templateId={templateId}
                  title="ground"
                />
              </ACLWrapper>
              )}
          />
          <Route
            path="/club"
            element={(
              <ACLWrapper section="club" type="actions" permission="update_club">
                <RouteElement
                  component={ClubView}
                  teamId={lastTeamId}
                  userId={id}
                  title="club"
                />
              </ACLWrapper>
              )}
          />
          {/* <Route path="/not_found" element={ (props) => {
          return <div>Not Found</div>
        }}/> */}

          <Route
            path="/fullscreen-session-player"
            element={(
              <RouteElement
                component={FullscreenWebplayer}
                title=""
              />
            )}
          />

          <Route
            path="/test-components"
            element={(
              <RouteElement
                component={TestComponents}
                title="test components"
              />
              )}
          />
          <Route
            path="/test-components/current"
            element={(
              <RouteElement
                component={TestComponentsCurrent}
                title="test components - current"
              />
              )}
          />
          <Route
            path="/test-components/player"
            element={(
              <RouteElement
                component={TestGroundsPlayer}
                title="test ground player"
              />
              )}
          />
          <Route
            path="/test-components/new-player"
            element={(
              <RouteElement
                component={TestComponentsNewPlayer}
                title="test new player"
              />
              )}
          />

          <Route
            path="/exports"
            element={(
              <RouteElement
                component={ExportsView}
                teamId={lastTeamId}
                userId={id}
                title="exports"
              />
              )}
          />
          <Route
            path="/changelog"
            element={(
              <RouteElement
                component={ChangelogView}
                title="changelog"
                teamId={lastTeamId}
                userId={id}
              />
              )}
          />
          <Route
            path="/my_stats"
            element={(
              <RouteElement
                component={MyStatsView}
                teamId={lastTeamId}
                userId={id}
                title="my stats"
              />
              )}
          />
          <Route
            path="/reset"
            element={(
              <RouteElement
                component={Login}
                title="login"
              />
              )}
          />
          <Route
            path="/"
            element={(
              <Navigate
                to={!me.hasBeenWelcomed ? '/welcome' : userCan({ section: 'dashboard', type: 'view', permission: 'page_view' }) ? '/dashboard' : '/tracks'}
                replace
              />
              )}
          />
          <Route
            path="*"
            element={(
              <RouteElement
                component={Page404}
                title="404 "
                teamId={lastTeamId}
                userId={id}
              />
              )}
          />
        </Routes>
      </Suspense>
    )
      : (
        <Suspense fallback={<MainLoader />}>
          <Routes>
            <Route
              path="*"
              element={(
                <RouteElement
                  component={ClubLockedView}
                  title="club locked"
                />
              )}
            />
          </Routes>
        </Suspense>
      )

  );
}

export default withMe(AppRoutes);
