import React, { useState, useEffect, useMemo } from 'react';
import { TextField } from 'components/form/TextField';
import Box from '@material-ui/core/Box';
import { Row } from 'components/layout/Row';
import { Column } from 'components/layout/Column';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTranslation, Trans } from 'react-i18next';
import { Dropzone } from 'components/form/Dropzone';
import { withMe } from 'components/MeUserWrapper';
import { MeType } from 'models/me';
import { useSnackbar } from 'notistack';
import CLIENT_SETTINGS from 'lib/client_settings';
import { UAParser } from 'ua-parser-js';
import { DEVICES, DeviceTypeRes, DeviceTypeVars } from 'query/device';
import { CACHE_AND_NETWORK } from 'lib/cache';
import { Alert } from '@material-ui/lab';
import { OutlinedWrapper } from './form/FieldsGroup';
import { HELPDESK_ISSUE_MAX_RETRIES, SNACKBAR_TIMEOUT_LONGER } from './constants';
import ScreenVideoCapture from './ScreenVideoCapture';
import { useQueryCached } from './utils/graphql';
import { SelectField } from './form/SelectField';
import * as Sentry from '@sentry/browser';

type PropsIn = {
  teamId?: string,
  setContentOpen: (open: boolean) => void,
  me: MeType,
  sendIssue: boolean,
  setSendIssue: (b: boolean) => void,
  setBlockSend: (b: boolean) => void,
}

const UserReportedDrawer = (props: PropsIn) => {
  const {
    me, teamId, setContentOpen, sendIssue, setSendIssue, setBlockSend,
  } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const currentPage = window.location.href;
  const [files, setFiles] = useState([]);
  const [screenshotFiles, setScreenshotFiles] = useState([]);
  const [videoFiles, setVideoFiles] = useState([]);
  const totalFiles = [...files, ...screenshotFiles, ...videoFiles];
  const commonPlaceholder = t('issues.content.placeholder.common', 'Insert a detailed description of the problem you experienced.');
  const specificPlaceholders = {
    '0-8': t('issues.content.placeholder.hardware', 'Please insert one report per each tracker.'),
    '0-4': t('issues.content.placeholder.bridge', 'Please specify which version of the gpexe bridge and what operative system you are using (e.g.: 5.01, Windows 10).'),
    '0-1': t('issues.content.placeholder.webApp', 'Please specify which browser and version you are using (e.g.: Chrome, version 71).'),
    '0-3': t('issues.content.placeholder.ipadApp', 'Please specify which version of the gpexe live iPad app you are using, and what firmware version is installed on the gpexe link (e.g.: iPad app 4.4, link firmware 2.9).'),
  };
  const { userAgent } = navigator;
  const parser = new UAParser(userAgent);
  const browser = parser.getBrowser();
  const opSystem = parser.getOS();
  const browserInfo = `${browser.name} - ${browser.version}`;
  const opSystemInfo = `${opSystem.name} - ${opSystem.version}`;
  const feVersion = CLIENT_SETTINGS.public.gpexeFeVersion;
  const beVersion = JSON.parse(localStorage.be_version);
  const form = CLIENT_SETTINGS.public.youtrackFormUUID;
  const [issueStates, setIssueStates] = useState({
    projectIssue: '',
    projectName: '',
    titleIssue: '',
    bodyIssue: '',
    issueId: null,
    serialNumber: [],
    isLink: false,
  });
  const isWebApp = issueStates.projectIssue === '0-1';
  const isIpadApp = issueStates.projectIssue === '0-3';
  const isBridge = issueStates.projectIssue === '0-4';
  const isHardware = issueStates.projectIssue === '0-8';

  const getFormUUID = (form) => {
    if (isWebApp) return form.webapp;
    if (isIpadApp) return form.ipadapp;
    if (isBridge) return form.bridge;
    return form.hardware;
  };

  const formUUID = getFormUUID(form);

  const getFormFields = () => {
    if (isWebApp) {
      return {
        environment: '193-36',
        club: '193-37',
        team: '193-38',
        reporterName: '193-40',
      };
    } if (isIpadApp) {
      return {
        environment: '193-45',
        club: '193-46',
        team: '193-47',
        reporterName: '193-48',
      };
    } if (isBridge) {
      return {
        environment: '193-49',
        club: '193-50',
        team: '193-51',
        reporterName: '193-52',
      };
    }
    return {
      environment: '193-41',
      club: '193-42',
      team: '193-43',
      reporterName: '193-44',
    };
  };

  const serialNumbers = issueStates?.serialNumber;
  const serialNumbersString = !issueStates.isLink ? serialNumbers.length > 0 ? serialNumbers.map((obj) => obj.value).join(', ') : '' : serialNumbers.join(', ');
  const serialNumbersRecap = isHardware ? issueStates.serialNumber.length ? `<strong>Serial Numbers Recap:</strong> ${serialNumbersString}` : '' : '';

  const { data: devices } = useQueryCached<DeviceTypeRes, DeviceTypeVars>(DEVICES, {
    variables: {
      clubId: me?.lastTeam?.club?.id || '',
      serialIds: null,
    },
    ...CACHE_AND_NETWORK,
  });

  const devicesData = devices?.res?.content;
  const serialIdOptions = useMemo(() => {
    const options = devicesData
      ?.filter((device) => device.serialId !== null)
      .map((device) => ({
        id: device.id,
        value: device.serialId,
      })) || [];
    return options;
  }, [devicesData]);

  const handleInputChange = (event) => {
    const input = event.target.value.trim();
    const splitItems = input
      .split(/\s*[,;-]\s*/)
      .filter((item) => item !== '');
  
    setIssueStates((prevState) => ({
      ...prevState,
      serialNumber: splitItems,
      isLink: splitItems.length > 0,
    }));
  };

  const formFields = getFormFields();
  const helpDeskUrl = `https://exelio.youtrack.cloud/api/feedbackForms/${formUUID}/submit`;
  const currentUrl = window.location.href;
  const parsedUrl = new URL(currentUrl);
  const { hostname } = parsedUrl;
  const subdomain = hostname.split('.')[0];
  const environment = subdomain.replace('-ui', '');
  const clubName = me?.lastTeam?.club?.name;
  const teamName = `${me?.lastTeam?.name} ${me?.lastTeam?.season}`;
  const reporterName = `${me?.firstName} ${me?.lastName}`;
  const [hardwareIssueType, setHardwareIssueType] = useState('tracker');

  const globalFields = [
    {
      $type: 'SimpleIssueCustomField',
      id: `${formFields.environment}`,
      value: `${environment}`,
    },
    {
      $type: 'SimpleIssueCustomField',
      id: `${formFields.club}`,
      value: `${clubName}`,
    },
    {
      $type: 'SimpleIssueCustomField',
      id: `${formFields.team}`,
      value: `${teamName}`,
    },
    {
      $type: 'SimpleIssueCustomField',
      id: `${formFields.reporterName}`,
      value: `${reporterName}`,
    },
  ];

  const getHardwareFields = (deviceId: string, serialId: string, uniqueId: string) => {
    const hardwareFields = [
      {
        $type: 'SimpleIssueCustomField',
        id: '193-35',
        value: deviceId,
      },
      {
        $type: 'SimpleIssueCustomField',
        id: '193-33',
        value: serialId,
      },
      {
        $type: 'SimpleIssueCustomField',
        id: '193-34',
        value: uniqueId,
      }];
    return hardwareFields;
  };

  const logSentryHelpdeskError = (response) => {
    Sentry.captureEvent({
      message: 'helpdesk issue creation',
      extra: {
        issueType: issueStates.projectName,
        response,
      },
    });
  }

  const createHelpDeskIssue = async (
    serialNumber: string = '',
    hardwareFields: object[] | undefined = undefined
  ) => {
    // request payload
    const formData = new FormData();
    formData.append(
      'feedbackForm',
      JSON.stringify({
        email: `${me?.email}`,
        summary: `${issueStates.titleIssue}`,
        description: `${issueStates.bodyIssue}\n${serialNumber}\n${serialNumbersRecap}\n<strong>From email:</strong> ${me?.email}\n<strong>From Page:</strong> ${currentPage}\n<strong>FE ver:</strong> ${feVersion}\n<strong>BE ver:</strong> ${beVersion}\n<strong>OS:</strong> ${opSystemInfo}\n<strong>Browser:</strong> ${browserInfo}`,
        fields: isHardware && hardwareFields ? [...globalFields, ...hardwareFields] : globalFields,
        articleViews: [],
      }),
    );
    // include attachments
    if (totalFiles.length) {
      totalFiles.forEach((file) => {
        formData.append('files', file);
      });
    }
  
    const options = {
      method: 'POST',
      body: formData,
    };
  
    const fetchWithRetry = async (url: string, options: RequestInit, retries: number = HELPDESK_ISSUE_MAX_RETRIES): Promise<Response> => {
      try {
        const response = await fetch(url, options);
        if (!response.ok) {
          logSentryHelpdeskError(response);
          throw new Error(`Error! Status: ${response.status}`);
        }
        return response;
      } catch (error) {
        logSentryHelpdeskError(error);
        if (retries > 0) {
          return fetchWithRetry(url, options, retries - 1);
        } else {
          throw new Error(`Error! Tried ${HELPDESK_ISSUE_MAX_RETRIES} times without success: Status: ${error}`);
        }
      }
    };
  
    try {
      await fetchWithRetry(helpDeskUrl, options);
      enqueueSnackbar(
        <Trans
          i18nKey="user.dialog.issueCreate"
          defaults="issue {{value}} successfully created"
          values={{ value: '' }}
        />,
        {
          variant: 'success',
          autoHideDuration: SNACKBAR_TIMEOUT_LONGER,
        },
      );
    } catch (error) {
      console.error('Helpdesk issue creation failed:', error);
    }
  };
  

  useEffect(() => {
    if (sendIssue) {
      if (serialNumbers.length > 0) {
        serialNumbers.forEach((sNumber) => {
          if (!issueStates.isLink) {
            const relatedDevice = devicesData?.find((obj) => obj.serialId === sNumber?.value);
            const hardwareFields = getHardwareFields(relatedDevice?.deviceId || '', relatedDevice?.serialId || '', relatedDevice?.uniqueId || '');
            const serialNumber = issueStates.serialNumber.length ? relatedDevice ? `<strong>Serial Number</strong>: ${relatedDevice.serialId}` : `<strong>Unknown Serial Number (tracker not found in club)</strong>: ${sNumber}` : '';
            createHelpDeskIssue(serialNumber, hardwareFields);
          } else {
            const hardwareFields = getHardwareFields('N/A', sNumber || '', 'N/A');
            const serialNumber = `<strong>Serial Number:</strong> ${sNumber}`;
            createHelpDeskIssue(serialNumber, hardwareFields);
          }
        });
      } else if (isHardware) {
        const serialNumber = '';
        const hardwareFields = getHardwareFields('N/A', 'N/A', 'N/A');
        createHelpDeskIssue(serialNumber, hardwareFields);
      } else {
        createHelpDeskIssue();
      }
      setContentOpen(false);
      setSendIssue(false);
    }
  }, [sendIssue]);

  useEffect(() => {
    setBlockSend(!issueStates.projectIssue || !issueStates.titleIssue || !issueStates.bodyIssue || isHardware && (['tracker', 'link'].includes(hardwareIssueType) && !issueStates.serialNumber.length));
  });

  return (
    <Box>
      <Box display="flex" flexDirection="row">
        <Column xs={12}>
          <Row>
            <Column xs={12}>
              <OutlinedWrapper label={`${t('issues.content.category', 'category')} *`}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={issueStates.projectIssue}
                  name="radio-buttons-group"
                  row
                  onChange={(v) => {
                    setIssueStates({
                      ...issueStates,
                      projectIssue: v.target.value,
                      projectName: v.target.id,
                    });
                  }}
                >
                  <FormControlLabel style={{ paddingRight: '7.5px' }} id="HARDWARE" value="0-8" control={<Radio />} label={t('issues.content.hardware', 'hardware')} />
                  <FormControlLabel style={{ paddingRight: '7.5px' }} id="BRIDGE" value="0-4" control={<Radio />} label="bridge" />
                  <FormControlLabel style={{ paddingRight: '7.5px' }} id="WEB APP" value="0-1" control={<Radio />} label={t('issues.content.webapp', 'web app')} />
                  <FormControlLabel style={{ paddingRight: '7.5px' }} id="IPAD APP" value="0-3" control={<Radio />} label={t('issues.content.ipadapp', 'ipad app')} />
                </RadioGroup>
              </OutlinedWrapper>
            </Column>
          </Row>
          <Row>
            <Column xs={12}>
              <TextField
                label={t('issues.content.title', 'title')}
                onChange={(e) => setIssueStates({ ...issueStates, titleIssue: e.target.value })}
                value={issueStates.titleIssue}
                required
              />
            </Column>
          </Row>
          {isHardware
        && (
        <Row>
          <Column xs={12}>
            <OutlinedWrapper>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={hardwareIssueType}
                name="radio-buttons-group"
                row
                onChange={(v) => setHardwareIssueType(v.target.value)}
              >
                <FormControlLabel id="TRACKER" value="tracker" control={<Radio />} label={t('table.tracker', 'tracker')} />
                <FormControlLabel id="LINK" value="link" control={<Radio />} label="link" />
                <FormControlLabel id="SUITCASE" value="suitcase" control={<Radio />} label={t('issues.content.suitcase', 'suitcase')} />
              </RadioGroup>
            </OutlinedWrapper>
          </Column>
          <Column xs={12}>
            {hardwareIssueType === 'tracker' ? (
              <SelectField
                id="serial-numbers"
                label={t('issues.content.serialNumber', 'serial number')}
                onChange={(e) => {
                  setIssueStates({ ...issueStates, serialNumber: e });
                }}
                canAddNewItems
                multiple
                fullWidth
                autocomplete
                options={serialIdOptions || []}
                value={issueStates.serialNumber || []}
                required
                disableEnter
                placeholder={t('issues.content.placeholder.selectFromList', 'select from the list')}
              />
            ) : hardwareIssueType === 'link' ? (
              <>
                <Alert
                  style={{ marginBottom: '15px', marginTop: '-5px' }}
                  severity="warning"
                >
                  {t('issues.content.placeholder.serialNumbersInfo', 'to add multiple links, separate the serial numbers with a comma')}
                </Alert>
                <TextField
                  label={t('issues.content.serialNumber', 'serial number')}
                  placeholder="D111111, D222222..."
                  onChange={handleInputChange}
                  required
                />
              </>
            ) : null }
          </Column>
        </Row>
        )}
          <Row>
            <Column xs={12}>
              <TextField
                required
                multiline
                minRow={5}
                value={issueStates.bodyIssue}
                label={t('issues.content.description', 'description')}
                placeholder={`${commonPlaceholder} ${specificPlaceholders[issueStates.projectIssue]}`}
                onChange={(e) => setIssueStates({ ...issueStates, bodyIssue: e.target.value })}
              />
            </Column>
          </Row>
          <Row>
            <Column xs={12}>
              <Dropzone
                showFileNames
                autoUpload={false}
                dropzoneMaxSize={20000000}
                method="POST"
                files={files}
                setFiles={setFiles}
              />
            </Column>
          </Row>
        </Column>
      </Box>
      <ScreenVideoCapture
        screenshotFiles={screenshotFiles}
        setScreenshotFiles={setScreenshotFiles}
        videoFiles={videoFiles}
        setVideoFiles={setVideoFiles}
      />
    </Box>
  );
};

export default withMe(UserReportedDrawer);
