/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* @ts-nocheck */

import { useQuery } from '@apollo/client';
import {
  addSeconds, format, intervalToDuration, isValid,
} from 'date-fns';
import {
  format as fmtTz, utcToZonedTime, getTimezoneOffset,
} from 'date-fns-tz';
import { SessionType, DrillType } from 'models/team_session';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { PlotBandProps } from 'react-jsx-highcharts';
import { TFunction } from 'react-i18next';
import {
  DATE_FORMAT, TIME_FORMAT, DATE_TIME_FORMAT, HIGHCHART_COLORS,
} from 'components/constants';
import Highcharts from 'highcharts';
import CLIENT_SETTINGS from '../../lib/client_settings';
import { GenericValueType } from '../../query/track';
import { formatterChoice } from './unitsFormatter';

export function round(value: number, precision: number) {
  const multiplier = 10 ** (precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

export function formattedTime(seconds: number) {
  const helperDate = addSeconds(new Date(0), seconds);
  return format(helperDate, 'hh:mm:ss');
}

export function roundOrDash(value: number, precision: number) {
  return value ? round(value, precision) : '-';
}

export function range(start:number, stop:number, step:number = 1) {
  // emulates Python's range function: start(included) stop (not included) step(optional)
  if (typeof stop === 'undefined') {
    stop = start;
    start = 0;
  }
  if (typeof step === 'undefined') {
    step = 1;
  }
  if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
    return [];
  }
  const result: number[] = [];
  for (let i = start; step > 0 ? i < stop : i > stop; i += step) {
    result.push(i);
  }
  return result;
}

export function rangeOptions(start:number, end:number) {
  // returns an array of objects for SelectField component:
  // Es: rangeOptions(1,3) => [{id:"0", value: "1"}, {id: "1", value: "1"}]
  const options = [];
  let id = 0;
  for (let i = start; i < end; i++) {
    options.push({ id: String(id), value: String(i) });
    id++;
  }
  return options;
}

// sort array safe mode
export const sortArray = (array: any[]) => {
  const arrayCopy = [...array];
  arrayCopy.sort();
  return arrayCopy;
};

export function objectsEqual(obj1: object, obj2: object) {
  if (JSON.stringify(obj1) === JSON.stringify(obj2)) {
    return true;
  }
  return false;
}

export function secondsToHMM(seconds: number): string {
  if (!seconds && seconds !== 0) {
    return '';
  }

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  return `${hours}:${minutes.toString().padStart(2, '0')}`;
}

export function secondsToHMMSS(seconds: number) {
  if (!seconds) {
    return '';
  }

  return new Date(seconds * 1000).toISOString().substr(12, 7);
}

export function secondsToHHMMSS(seconds: number) {
  if (!seconds) {
    return '';
  }

  return new Date(seconds * 1000).toISOString().substr(11, 8);
}

export function secondsToLocaleHHMMSS(seconds: number) {
  if (!seconds) {
    return '';
  }

  return new Date(seconds * 1000).toLocaleTimeString();
}

export const durationToHHMMSS = (duration: number) => {
  if (duration === null || duration === undefined) {
    return '-';
  }
  const hoursSrc = Math.floor(duration / 3600);
  const minutesSrc = Math.floor((duration - hoursSrc * 3600) / 60);
  const secondsSrc = Math.floor(duration - hoursSrc * 3600 - minutesSrc * 60);

  let hours = hoursSrc.toString();
  let minutes = minutesSrc.toString();
  let seconds = secondsSrc.toString();

  if (hoursSrc > 0) {
    hours += ':';
  } else {
    hours = '';
  }

  if (minutesSrc > 0) {
    if (minutesSrc < 10) {
      minutes = `0${minutesSrc}`;
    }
    minutes += ':';
  } else {
    minutes = '00:';
  }

  if (secondsSrc > 0) {
    if (secondsSrc < 10) {
      seconds = `0${secondsSrc}`;
    }
  } else {
    seconds = '00';
  }

  return hours + minutes + seconds;
};

export const durationToMMSS = (duration: number) => {
  if (duration === null || duration === undefined) {
    return '-';
  }

  const minutes = Math.floor(duration / 60);
  const seconds = Math.floor(duration % 60);

  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes.toString();
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds.toString();

  return `${formattedMinutes}:${formattedSeconds}`;
};


export const formatDiffTimeHHMMSS = (from: number, to: number) => {
  const interval = intervalToDuration({ start: new Date(to), end: new Date(from) });
  return `${String(interval.hours).padStart(2, '0')}:${String(interval.minutes).padStart(2, '0')}:${String(interval.seconds).padStart(2, 0)}`;
};

/**
 * @deprecated utilizzare secondsToMmSs
 * @param seconds
 */
export function secondsToMMSS(seconds: number) {
  // @todo da rimuovere, in alcuni casi non lavora correttamente ---> sostituirla con secondsToMmSs
  return new Date(seconds * 1000).toISOString().substr(14, 5);
}

export function secondsToMin(seconds: number) {
  return round(seconds / 60, 1);
}

export function getNumDays(date1:string | Date, date2: string | Date) {
  // return the number of days between 2 dates:
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  return Math.ceil((d1.getTime() - d2.getTime()) / (1000 * 3600 * 24));
}

export function getMonthName(date: Date): string {
  return date.toLocaleString('en-EN', { month: 'long' }).toLowerCase();
}

export function getDayName(date: Date): string {
  return date.toLocaleString('en-EN', { weekday: 'long' }).toLowerCase();
}

export const setDays = (d:Date | string, days: number, operation: '+' | '-') : string => {
  const date = new Date(d);
  if (operation === '+') return format(date.setDate(date.getDate() + days * 1), DATE_FORMAT); // sum (n) days
  return format(date.setDate(date.getDate() - days * 1), DATE_FORMAT); // subtract (n) days
};

export const setWeeks = (d:Date | string, weeks: number, operation: '+' | '-') : string => {
  const date = new Date(d);
  if (operation === '+') return format(date.setDate(date.getDate() + weeks * 7), DATE_FORMAT); // sum (n) weeks
  return format(date.setDate(date.getDate() - weeks * 7), DATE_FORMAT); // subtract (n) weeks
};

export const setMonths = (d:Date | string, months: number, operation: '+' | '-') : string => {
  const date = new Date(d);
  if (operation === '+') return format(date.setDate(date.getDate() + months * 30), DATE_FORMAT); // sum (n) months
  return format(date.setDate(date.getDate() - months * 30), DATE_FORMAT); // subtract (n) months
};

export const getFirstDayNextMonth = (d: Date | string) : string => {
  const inputDate = typeof d === 'string' ? new Date(d) : d;
  const currentYear = inputDate.getFullYear();
  const currentMonth = inputDate.getMonth();
  let newYear = currentYear;
  let newMonth = currentMonth + 1;
  if (currentMonth === 12) {
    newYear += 1;
    newMonth = 0; // January (0-indexed)
  }
  const firstDayOfNextMonth = format(new Date(newYear, newMonth, 1), DATE_FORMAT);
  return firstDayOfNextMonth;
};

export const getLastDayOfMonth = (d: Date | string): string => {
  const inputDate = typeof d === 'string' ? new Date(d) : d;
  const currentYear = inputDate.getFullYear();
  const currentMonth = inputDate.getMonth();
  const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0);
  const formattedLastDayOfMonth = format(lastDayOfMonth, DATE_FORMAT);
  return formattedLastDayOfMonth;
};

export const getMonday = (): string => {
  const today = new Date();
  const day = today.getDay();
  const diff = today.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  return format(new Date(today.setDate(diff)), DATE_FORMAT);
};

export const getDayBefore = (date: Date | string): string => {
  const inputDate = typeof date === 'string' ? new Date(date) : date;
  const dayBefore = new Date(inputDate);
  dayBefore.setDate(inputDate.getDate() - 1);
  return format(dayBefore, DATE_FORMAT);
};

export const isDateBetween = (startDate: string, endDate: string, dateToCheck: string | null) => {
  // returns true if dateToCheck is between startDate and endDate (included) or dateToCheck is null or ''.
  const start = Date.parse(startDate);
  const end = Date.parse(endDate);
  const d = Date.parse(dateToCheck);
  return d >= start && d <= end;
};

export function calculateDaysDifference(start: Date, finish: Date): number {
  return Math.trunc((finish.getTime() - start.getTime()) / (1000 * 3600 * 24)) + 1;
}

export const diffTime = (from, to) => intervalToDuration({
  start: from,
  end: to,
});

/**
 * Ricava l'orario dal timestamp in millisecondi
 * @param timestamp
 */
export const timestampToHHMMSS = (timestamp) => secondsToHHMMSS(timestamp / 1000);
export const timestampToLocaleHHMMSS = (timestamp) => secondsToLocaleHHMMSS(timestamp / 1000);

export const timeToMilliseconds = (time : string) => {
  // transform a time format es: 01:00:00 in milliseconds
  const timeComponents = time.split(':').map(Number);

  if (timeComponents.length === 2) {
    const [minutes = 0, seconds = 0] = timeComponents;
    const totalMilliseconds = minutes * 60000 + seconds * 1000;
    return totalMilliseconds;
  }
  const [hours = 0, minutes = 0, seconds = 0] = timeComponents;
  const totalMilliseconds = hours * 3600000 + minutes * 60000 + seconds * 1000;
  return totalMilliseconds;
};

export const diffTimeFormatted = (from, to, withHours = false) => {
  const interval = diffTime(from, to);

  return `${
    withHours
      ? `${String(interval.hours).padStart(2, '0')}:`
      : ''
  }${String(interval.minutes).padStart(2, '0')}:${String(interval.seconds).padStart(2, '0')}`;
};

export const intervalFormatted = (milliseconds, withHours = false) => {
  if (!milliseconds) {
    return '';
  }
  const interval = intervalToDuration({
    start: 0,
    end: milliseconds,
  });

  return `${
    withHours
      ? `${String(interval.hours).padStart(2, '0')}:`
      : ''
  }${String(withHours
    ? interval.minutes
    : (interval.hours || 0) * 60 + (interval.minutes || 0)).padStart(2, '0')}:${String(interval.seconds).padStart(2, '0')}`;
};

export function formattedColor(color: string) {
  return color.replace('_', '#');
}
export const DEFAULT_ROWS_PER_PAGE = 50;
export const MIN_ROWS_PER_PAGE = 10;
export const DEFAULT_ROWS_PER_PAGE_OPTIONS = [50];

export const TABLE_PAGINATION_MODE = 'server';
export const TABLE_FILTER_MODE = 'server';
export const TABLE_SORTING_MODE = 'server';

export function getNatureAbbr(key: SessionType | null) {
  if (!key) return '-';
  switch (key) {
    case 'DRILL':
      return 'D';
    case 'RPE':
      return 'SR';
    case 'RPE_ONLY':
      return 'R';
    case 'STD':
      return 'S';
    default:
      return '-';
  }
}

export const useImperativeQuery = (query) => {
  const { refetch } = useQuery(query, { skip: true });

  return (variables) => refetch(variables);
};

export const useQueryToObject = (query, variables) => {
  const { loading, error, data } = useQuery(query, variables);
  if (loading) return 'loading...';
  if (error) return 'error...';
  return data.res;
};

export function formatDateToYYYYMMDD(date: Date): string {
  return `${date.toISOString().split('T')[0]}`;
}

export function formatDateTimeToHHMMSS(date:Date):string {
  const dateObject = new Date(date);
  const hours = dateObject.getHours().toString().padStart(2, '0');
  const minutes = dateObject.getMinutes().toString().padStart(2, '0');
  const seconds = dateObject.getSeconds().toString().padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
}

/**
 * Generates range label like
 * - `>1.5m/s²`
 * - `1.5 - 2.5m/s²`
 * - `2.5 - 3.5m/s²`
 * @param props
 * @constructor
 */
export function RangeLabel(props: { lowerBound: GenericValueType; upperBound: GenericValueType }) {
  const noLower = !props.lowerBound || props.lowerBound.value === null;
  const noUpper = !props.upperBound || props.upperBound.value === null;
  const chosenFormatter = formatterChoice(props.upperBound.unit
    || props.lowerBound.unit);
  const boundUom = props.upperBound.uom || props.lowerBound.uom;

  return noLower
    ? noUpper
      ? null
      : `< ${chosenFormatter(boundUom, props.upperBound.value)} ${props.upperBound.uom}`
    : noUpper
      ? `>${chosenFormatter(boundUom, props.lowerBound.value)} ${props.lowerBound.uom}`
      : `${chosenFormatter(boundUom, props.lowerBound.value)} - ${chosenFormatter(boundUom, props.upperBound.value)} 
      ${props.upperBound.uom}`;
}

export function isDate(date) {
  return isValid(date);
}

export function isFloat(value) {
  if (
    typeof value === 'number'
    && !Number.isNaN(value)
    && !Number.isInteger(value)
  ) {
    return true;
  }
  return false;
}

export const throttle = (callback, wait, immediate = false) => {
  let timeout: null | ReturnType<typeof setTimeout> = null;
  let initialCall = true;

  return function () {
    const callNow = immediate && initialCall;
    const next = () => {
      callback.apply(this, arguments);
      timeout = null;
    };

    if (callNow) {
      initialCall = false;
      next();
    }

    if (!timeout) {
      timeout = setTimeout(next, wait);
    }
  };
};

export function useStateCallback(initialState) {
  const [state, setState] = useState(initialState);
  const cbRef = useRef(null);

  const setStateCallback = useCallback((state, cb?) => {
    cbRef.current = cb;
    setState(state);
  }, []);

  useEffect(() => {
    if (cbRef.current) {
      // @ts-ignore
      cbRef.current(state);
      cbRef.current = null;
    }
  }, [state]);

  return [state, setStateCallback];
}

/**
 * Da rimuovere in favore di {@link capitalizeName} ?
 * @param name
 * @deprecated
 */
export function formatName(name: string) {
  const cleanedName = name.replace(/\s+/g, ' ').trim(); // remove possible extra white spaces
  const nameParts = cleanedName.toLowerCase().split(' ');
  return nameParts.map((part) => part[0].toUpperCase() + part.slice(1)).join(' ');
}

export function isValidDate(d) {
  return d instanceof Date && !isNaN(d.getTime());
}

export const areOverlappingPlotBands = (
  limitBand: [number, number],
  localBand: [number, number],
) => localBand[0] >= limitBand[0] && localBand[0] <= limitBand[1]
  || localBand[1] >= limitBand[0] && localBand[1] <= limitBand[1]
  || localBand[0] <= limitBand[0] && localBand[1] >= limitBand[1];

export const getIntersections = (band: PlotBandProps, localPlotBands: {from: number, to: number}[]) => Array.from(localPlotBands)
  .filter((lb) => areOverlappingPlotBands([lb.from, lb.to], [band.from!, band.to!]))
  .sort((a, b) => (a.from > b.from ? 1 : -1));

export const intersectLocalPlotBands = (limitBand: [number, number], localBand: [number, number]) => {
  const leftLimit = localBand[0] >= limitBand[0] && localBand[0] <= limitBand[1] ? localBand[0] : limitBand[0];
  const rightLimit = localBand[1] <= limitBand[1] && localBand[1] >= limitBand[0] ? localBand[1] : limitBand[1];
  return [leftLimit, rightLimit];
};

export const mmssToSeconds = (mmss) => {
  if (mmss) {
    const [mm, ss] = mmss.split(':');
    if (mm && ss) {
      return mm * 60 + ss * 1;
    }
    return mm * 60;
  }
  return 0;
};

export const computeDifference = (start, end) => {
  if (start && end) {
    return parseInt(end, 10) - parseInt(start, 10);
  }
  if (start && !end) {
    return parseInt(start, 10);
  }
  if (!start && end) {
    return parseInt(end, 10);
  }
  return 0;
};

export const computeDuration = (start, end, defaultValue) => {
  if (!start || !end) return defaultValue;
  const [sh, sm, ss] = start.split(':').map(Number);
  const [eh, em, es] = end.split(':').map(Number);

  let totalMinutes = (eh - sh) * 60 + (em - sm);
  let seconds = computeDifference(ss, es);

  if (seconds < 0) {
    totalMinutes -= 1;
    seconds += 60;
  }

  if (totalMinutes < 0) return '0:00';

  return `${totalMinutes}:${seconds.toString().padStart(2, '0')}`;
};

export const debounce = (fn, ms) => {
  let timer;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      // eslint-disable-next-line no-undef
      // @ts-ignore
      fn.apply(this, arguments);
    }, ms);
  };
};

export const secondsToMmSs = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const sec = Math.floor(seconds % 60);
  return `${`${minutes}`.length < 2 ? `0${minutes}` : minutes}:${`${sec}`.length === 2 ? sec : `0${sec}`}`;
};

export const secondsToHhMmSsString = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - hours * 3600) / 60);
  const sec = Math.floor(seconds % 60);
  return `${hours ? `${hours}h ` : ''}${minutes ? `${minutes}m ` : ''}${`${sec}`.length === 2 ? sec : `0${sec}`}s`;
};

export const secondsToHh = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  return `${hours ? `${hours}h ` : ''}`;
};

export const hasElapsed = (datetimeString, minutes) => {
  /* This function checks if a specified amount of time (in minutes) has elapsed since a given datetime string. */
  const initialTime = new Date(datetimeString);
  const currentTime = new Date();
  const elapsedTime = currentTime - initialTime;
  const minutesInMilliseconds = minutes * 60 * 1000;
  return elapsedTime >= minutesInMilliseconds;
};

/**
 * Converts a given string to title case.
 *
 * @param str - The string to be converted. Can be undefined or a string.
 * @returns - The converted string in title case.
 */
export const convertToTitle = (str: undefined | string) => {
  if (!str || str === '') {
    return '';
  }

  return str.replace(/\s+/g, ' ').trim() // remove possible extra white spaces
    .toLowerCase()
    .split(' ')
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(' ');
};

export const handleRawDownload = (url: string, onS3: boolean) => {
  if (url) {
    if (onS3) {
      window.open(url);
    } else {
      window.location.href = `${CLIENT_SETTINGS.public.gpexeBackendUrl?.slice(0, -1)}${url}`;
    }
  }
};

export const isJsonString = (str: any) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export function getUomTranslation(t: TFunction<string, undefined>, uom?: string) {
  const ignoredValues = ['', null, undefined];
  const ignoredTranslations = ['%'];
  if (ignoredValues.includes(uom)) {
    return ' ';
  }
  if (uom && ignoredTranslations.includes(uom)) {
    return uom;
  }
  return t(`uom.${uom?.split(/\s+/).join('')}`, uom);
}

export const newDefaultDate = (date: Date) => new Date(date.setHours(0, 0, 0, 0));

export function timestampOffset(timestampDate:string) {
  const offset = Math.abs(new Date().getTimezoneOffset() / 60);
  const date = new Date(timestampDate);
  date.setHours(date.getHours() + offset); // add offset
  const timeStampOffset = date.getTime();
  return timeStampOffset;
}

export const dateTimeToUtc = (date: Date | null): Date | null => {
  if (!isDate(date)) return date;
  if (date === null) {
    return null;
  }
  // check if date is already in UTC
  const isUTC = date.toISOString().endsWith('Z');
  if (isUTC) {
    return date;
  }
  // convert to UTC by adjusting timezone offse
  const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  return utcDate;
};

export const dateToUtc = (date: Date | null): string | null => {
  if (!isDate(date)) return date;
  if (date === null) {
    return null;
  }
  const isUTC = date.toISOString().endsWith('Z');
  if (isUTC) {
    return format(date, DATE_FORMAT);
  }
  // Convert the date to UTC by adjusting the timezone offset and return a date format.
  const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  return format(utcDate, DATE_FORMAT);
};

export function formatColorPicker(color:string) {
  if (color && color.includes('#')) {
    return color.replace('#', '_');
  }
  return color;
}

export function formatColor(color: string) {
  if (color && color.includes('_')) {
    return color.replace('_', '#');
  }
  return color;
}

export const getHighchartsColor = (id: number) => {
  const index = id % HIGHCHART_COLORS.length;
  return HIGHCHART_COLORS[index];
};

export function minutesToMMSS(value: number) {
  let finalFormat: string = '';
  const mins: number = Math.floor(value);
  const secs: number = Math.round((value - mins) * 60);
  if (mins < 10) {
    finalFormat += `0${mins}`;
  } else {
    finalFormat += mins;
  }
  if (secs < 10) {
    finalFormat += `:0${secs}`;
  } else {
    finalFormat += `:${secs}`;
  }
  return finalFormat;
}

export const getDrillsTimeDifference = (timeFrom:string, timeTo:string) => { // format 00:00:00
  if (timeTo.length === 8 && timeFrom.length === 8) {
    const time1 = new Date(`1970-01-01T${timeTo}Z`);
    const time2 = new Date(`1970-01-01T${timeFrom}Z`);
    // time difference in milliseconds
    const timeDifference = time1 - time2;
    // time difference to Date object
    const resultTime = new Date(timeDifference);
    const resultFormatted = resultTime.toISOString().substr(11, 8);
    return resultFormatted;
  }
  return '';
};

export const isValidTimeFormat = (value) => {
  // regex to match '00:00:00' format
  const timeFormatRegex = /^([0-1][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
  return timeFormatRegex.test(value);
};

export const roundDate = (time: string, returnDate?: boolean) => {
  const d = new Date(Math.round(new Date(time).getTime() / 1000) * 1000);

  return returnDate ? d : d.toISOString();
};

export const roundDrills = (drills: DrillType[]) => drills.map((d) => ({
  ...d,
  ...{
    start: d.start
      ? roundDate(d.start)
      : d.start,
    end: d.end
      ? roundDate(d.end)
      : d.end,
  },
}));

export const findUniqueMaxValueObject = (arr: object[], key: string) => {
  /* takes an array of objects and a key to compare and returns the object with the max value,
  if more than 1 max value, returns undefined. */
  if (arr.length === 0) return undefined;

  let maxObject = { [key]: -1 };
  let isUnique = true;

  arr.forEach((obj, index) => {
    if (index === 0 || obj[key] > maxObject[key]) {
      maxObject = obj;
      isUnique = true;
    } else if (obj[key] === maxObject[key]) {
      isUnique = false;
    }
  });

  return isUnique ? maxObject : undefined;
};

export const getOldUiUrl = () => {
  const { host } = window.location;
  if (host === 'localhost:8080') {
    const localhostNewUI = host.replace(/8080/, '8000');
    return `http://${localhostNewUI}/`;
  }
  const hostNewUI = host.replace('-ui', '');
  return `https://${hostNewUI}/`;
};

export function capitalizeFirstLetter(inputString: string, ofAllWords: boolean = true): string {
  if (ofAllWords) {
    const lowercaseString = inputString.toLowerCase();
    const words = lowercaseString.split(' ');
    const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    return capitalizedWords.join(' ');
  }
  return inputString.charAt(0).toUpperCase() + inputString.slice(1);
}

export function abbreviateWords(phrase: string): string {
  const words = phrase.split(' ');
  const abbreviatedWords = words.map((word) => word.charAt(0).toUpperCase());
  return abbreviatedWords.join(' ');
}

export function isChinese(text: string): boolean {
  for (const character of text) {
    if (character >= '\u4e00' && character <= '\u9fff') {
      return true;
    }
  }
  return false;
}

export function formatAthleteName(
  firstName: string | null = null,
  lastName: string | null = null,
  abbreviate: boolean = false,
  lastNameGoesFirst: boolean = true,
): string {
  /** returns different formats for Chinese vs. standard names */
  const fullNameNoSpacing = `${firstName ?? ''}${lastName ?? ''}`;

  if (isChinese(fullNameNoSpacing)) {
    return fullNameNoSpacing;
  }
  const formattedFirstName = abbreviate ? abbreviateWords(firstName ?? '') : firstName;
  return capitalizeFirstLetter(
    lastNameGoesFirst
      ? `${lastName ?? ''} ${formattedFirstName ?? ''}`.trim()
      : `${formattedFirstName ?? ''} ${lastName ?? ''}`.trim(),
  );
}
