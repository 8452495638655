import { Ref } from 'react';
import { StringGenericValueType } from '../athlete/defines';
import { getBearing, gpsToLocal } from '../../lib/geo';
import { GroundType } from '../../models/ground';
import { toNumber } from '../../lib/numbers';
import { SeriesState } from '../webPlayer/WebPlayer';
import { timestampSearchRange } from '../webPlayer/Ground';

type Ground = {
  name: string;
  groundType: string;
  groundSurface: string;
  groundSurfaceColor: string;
  vertexAX: Omit<StringGenericValueType, 'value'> & { value: string };
  vertexAY: Omit<StringGenericValueType, 'value'> & { value: string };
  vertexBX: Omit<StringGenericValueType, 'value'> & { value: string };
  vertexBY: Omit<StringGenericValueType, 'value'> & { value: string };
  vertexCX: Omit<StringGenericValueType, 'value'> & { value: string };
  vertexCY: Omit<StringGenericValueType, 'value'> & { value: string };
  vertexDX: Omit<StringGenericValueType, 'value'> & { value: string };
  vertexDY: Omit<StringGenericValueType, 'value'> & { value: string };
  vertexALongitude: string;
  vertexALatitude: string;
  vertexBLongitude: string;
  vertexBLatitude: string;
  vertexCLongitude: string;
  vertexCLatitude: string;
  vertexDLongitude: string;
  vertexDLatitude: string;
};

/**
 * Regular expression to validate a numeric value.
 */
export const regexp = /^-?([0-9]*[.])?[0-9]+$/;

/**
 * Checks if the local vertex values of a given Ground object are set and
 * match a regular expression pattern.
 *
 * @param ground - The Ground object containing vertex values.
 * @returns - `true` if all local vertex values are set and match
 * the regular expression pattern, `false` otherwise.
 */
export const isSetLocal = (ground: Ground) => (
  (ground.vertexAX.value !== '' && regexp.test(ground.vertexAX.value))
  && (ground.vertexAY.value !== '' && regexp.test(ground.vertexAY.value))
  && (ground.vertexBX.value !== '' && regexp.test(ground.vertexBX.value))
  && (ground.vertexBY.value !== '' && regexp.test(ground.vertexBY.value))
  && (ground.vertexCX.value !== '' && regexp.test(ground.vertexCX.value))
  && (ground.vertexCY.value !== '' && regexp.test(ground.vertexCY.value))
  && (ground.vertexDX.value !== '' && regexp.test(ground.vertexDX.value))
  && (ground.vertexDY.value !== '' && regexp.test(ground.vertexDY.value))
);

/**
 * Checks if all the ground vertices have valid longitude and latitude values.
 *
 * @param ground - The ground object containing vertex information.
 * @returns - Returns true if all vertices have valid longitude and latitude values, otherwise false.
 */
export const isSetGlobal = (ground: Ground) => (
  (ground.vertexALongitude !== '' && regexp.test(ground.vertexALongitude))
    && (ground.vertexALatitude !== '' && regexp.test(ground.vertexALatitude))
    && (ground.vertexBLongitude !== '' && regexp.test(ground.vertexBLongitude))
    && (ground.vertexBLatitude !== '' && regexp.test(ground.vertexBLatitude))
    && (ground.vertexCLongitude !== '' && regexp.test(ground.vertexCLongitude))
    && (ground.vertexCLatitude !== '' && regexp.test(ground.vertexCLatitude))
    && (ground.vertexDLongitude !== '' && regexp.test(ground.vertexDLongitude))
    && (ground.vertexDLatitude !== '' && regexp.test(ground.vertexDLatitude))
);

/**
 * Validates the given ground object based on the specified coordinates type.
 *
 * @param ground - The ground object to validate.
 * @param [coordsType] - The type of coordinates to validate. Default is 'undefined'.
 * @returns - Returns true if the ground object is valid based on the specified coordinates type, otherwise false.
 */
export const validate = (ground: Ground, coordsType?: string) => {
  if (ground.name === '') {
    return false;
  }
  if (coordsType === 'LOCAL') {
    return isSetLocal(ground);
  } if (coordsType === 'GLOBAL') {
    return isSetGlobal(ground);
  }
  return ((isSetLocal(ground) && !isSetGlobal(ground)) || (isSetGlobal(ground) && !isSetLocal(ground)));
};

/**
 * Returns the default vertex values for a given ground type.
 *
 * @param groundType - The type of ground.
 * @returns - The default vertex values.
 */
export const vertexDefaultValues = (groundType: string) => {
  switch (groundType) {
    case 'FOOTBALL':
      return {
        vertexBY: '60',
        vertexCX: '100',
        vertexCY: '60',
        vertexDX: '100',
      };
    case 'FUTSAL':
      return {
        vertexBY: '20',
        vertexCX: '40',
        vertexCY: '20',
        vertexDX: '40',
      };
    case 'BASKET':
      return {
        vertexBY: '15',
        vertexCX: '28',
        vertexCY: '15',
        vertexDX: '28',
      };
    case 'RUGBY':
      return {
        vertexBY: '60',
        vertexCX: '120',
        vertexCY: '60',
        vertexDX: '120',
      };
    case 'RUGBY_H':
      return {
        vertexBY: '30',
        vertexCX: '60',
        vertexCY: '30',
        vertexDX: '60',
      };
    case 'BADMINTON':
      return {
        vertexBY: '5',
        vertexCX: '13',
        vertexCY: '5',
        vertexDX: '13',
      };
    case 'HANDBALL':
      return {
        vertexBY: '20',
        vertexCX: '40',
        vertexCY: '20',
        vertexDX: '40',
      };
    case 'SHORT_TRACK':
      return {
        vertexAX: '15',
        vertexAY: '9',
        vertexBX: '10',
        vertexBY: '24',
        vertexCX: '37',
        vertexCY: '33',
        vertexDX: '42',
        vertexDY: '18',
      };
    case 'GAELIC_FOOTBALL':
      return {
        vertexBY: '90',
        vertexCX: '145',
        vertexCY: '90',
        vertexDX: '145',
      };
    case 'FENCING':
      return{
        vertexBY: '2',
        vertexCX: '18',
        vertexCY: '2',
        vertexDX: '18',
      }

    default:
      return {
        vertexBY: '0',
        vertexCX: '0',
        vertexCY: '0',
        vertexDX: '0',
      };
  }
};

/**
 * Calculates the scale factor based on the width, height, window width, and window height.
 *
 * @param width - The width of the object.
 * @param height - The height of the object.
 * @param winWidth - The width of the window.
 * @param winHeight - The height of the window.
 * @returns The scale factor.
 */
export const getScale = (
  width: number,
  height: number,
  winWidth: number,
  winHeight: number,
) => {
  const scaleX = winWidth / width;
  const scaleY = winHeight / height;
  return Math.min(scaleX, scaleY);
};

/**
 * Converts GPS coordinates to local coordinates on a map.
 * @param props - The properties needed for the conversion.
 * @param props.ground - The ground object containing the GPS coordinates of the vertices.
 * @param props.WINDOW_WIDTH - The width of the window or canvas.
 * @param props.WINDOW_HEIGHT - The height of the window or canvas.
 * @returns - An object containing the local coordinates and other calculated values.
 */
export const coordsGPSToLPS = (props: {ground: GroundType | undefined, WINDOW_WIDTH: number, WINDOW_HEIGHT: number}) => {
  const {
    ground,
    WINDOW_WIDTH,
    WINDOW_HEIGHT,
  } = props;

  if (!ground) {
    return {
      localBearing: 0,
      vertexA: 0,
      vertexB: 0,
      vertexC: 0,
      vertexD: 0,
      width: 0,
      height: 0,
      scale: 1,
    };
  }

  const {
    vertexALatitude,
    vertexALongitude,
    vertexBLatitude,
    vertexBLongitude,
    vertexCLatitude,
    vertexCLongitude,
    vertexDLatitude,
    vertexDLongitude,
  } = ground;

  const localBearing = getBearing(
    { latitude: vertexALatitude, longitude: vertexALongitude },
    { latitude: vertexBLatitude, longitude: vertexBLongitude },
  );

  // ricalcolo i vertici considerando A come punto 0,0 e convertendo coordinate GPS in locali
  const vertexA = {
    x: 0.00000001, // Konva fix
    y: 0.00000001, // Konva fix
  };

  const vertexB = gpsToLocal(
    { latitude: vertexALatitude, longitude: vertexALongitude },
    { latitude: vertexBLatitude, longitude: vertexBLongitude },
    90 - localBearing,
    true,
  );
  const vertexC = gpsToLocal(
    { latitude: vertexALatitude, longitude: vertexALongitude },
    { latitude: vertexCLatitude, longitude: vertexCLongitude },
    90 - localBearing,
    true,
  );
  const vertexD = gpsToLocal(
    { latitude: vertexALatitude, longitude: vertexALongitude },
    { latitude: vertexDLatitude, longitude: vertexDLongitude },
    90 - localBearing,
    true,
  );

  // calcolo le dimensioni del campo (dai dati)
  const width = Math.abs(vertexC.x - vertexA.x);
  const height = Math.abs(vertexC.y - vertexA.y);

  // calcolo il valore della scala per adattare il campo alla canvas
  const scaleValue = getScale(
    width,
    height,
    WINDOW_WIDTH,
    WINDOW_HEIGHT,
  ) * 0.85;

  return {
    localBearing,
    vertexA,
    vertexB,
    vertexC,
    vertexD,
    width,
    height,
    scale: scaleValue,
  };
};

/**
 * Retrieves the sizes from the given ref object.
 *
 * @param ref - The ref object.
 * @returns An object containing the window width and height.
 */
export const getSizesFromRef = (ref: Ref<HTMLDivElement | undefined>) => {
  const isRefValid = ref != null && typeof ref !== 'function' && ref.current;

  const WINDOW_WIDTH = isRefValid ? ref?.current?.offsetWidth || 1 : 1;
  const WINDOW_HEIGHT = isRefValid
    ? ref?.current?.offsetHeight && ref?.current?.offsetHeight > 1
      ? ref.current.offsetHeight
      : ref?.current?.offsetWidth
        ? ref.current.offsetWidth * 9 / 16 // @todo verificare se la proporzione è valida
        : 1
    : 1;

  return {
    WINDOW_WIDTH,
    WINDOW_HEIGHT,
  };
};

interface GetPlayersPointsLPSProps {
  cursor: number;
  enabledPlayers: Array<string>;
  scale: number,
  series: SeriesState;
}

interface GetPlayersPointsGPSProps extends GetPlayersPointsLPSProps {
  bearing: number;
  groundData: GroundType | null;
}

export const getPlayersPointsLPS = (props: GetPlayersPointsLPSProps) => {
  const {
    cursor,
    enabledPlayers,
    scale,
    series,
  } = props;

  return Array.from(enabledPlayers)
    .reduce((acc, playerID) => {
      if (!series[playerID]) {
        return acc;
      }

      const idx = cursor === 0
        ? 0
        : series[playerID].pathData.findIndex((point) => Math.abs(cursor - point.timestamp) < timestampSearchRange);

      const serieX = series && series[playerID].pathData[idx] && series[playerID].pathData[idx].x !== null && series[playerID].pathData[idx].x;
      const serieY = series && series[playerID].pathData[idx] && series[playerID].pathData[idx].y !== null && series[playerID].pathData[idx].y;

      return {
        ...acc,
        ...series[playerID].pathData[idx] && {
          [playerID]: {
            x: serieX ? serieX * scale : null,
            x_0: serieX || 0,
            y: serieY ? serieY * scale : null,
            y_0: serieY || 0,
            scale,
          },
        } || {},
      };
    }, {});
};

export const getPlayersPointsGPS = (props: GetPlayersPointsGPSProps) => {
  const {
    bearing,
    cursor,
    enabledPlayers,
    groundData,
    scale,
    series,
  } = props;

  if (!enabledPlayers) {
    return {};
  }

  return enabledPlayers
    .reduce((acc, playerID) => {
      if (!series[playerID]) {
        return acc;
      }

      const idx = cursor === 0
        ? 0
        : series[playerID].pathData.findIndex((point) => Math.abs(cursor - point.timestamp) < timestampSearchRange);

      const serie = series
      && series[playerID].pathData[idx]
      && series[playerID].pathData[idx].x_0 !== null
      && series[playerID].pathData[idx].y_0 !== null
      && groundData?.vertexALatitude
      && gpsToLocal(
        {
          latitude: groundData?.vertexALatitude,
          longitude: groundData?.vertexALongitude,
        },
        {
          latitude: series[playerID].pathData[idx].x_0,
          longitude: series[playerID].pathData[idx].y_0,
        },
        90 - bearing,
        true,
      );

      return {
        ...acc,
        ...series[playerID].pathData[idx] && {
          [playerID]: {
            x: serie ? serie.x * scale : null,
            x_0: serie && serie.x || 0,
            y: serie ? -serie.y * scale : null,
            y_0: serie && serie.y || 0,
            scale,
          },
        } || {},
      };
    }, {});
};

interface GetPlayersLinesProps {
  playerLinks: Set<[string, string]>,
  playersPoints: {[k: string]: {
    x: number | null,
    x_0: number| null,
    y: number | null,
    y_0: number | null,
  }}
}

export const getPlayersLines = (props: GetPlayersLinesProps) => {
  const {
    playersPoints,
    playerLinks,
  } = props;

  if (playerLinks) {
    return Array
      .from(playerLinks)
      .reduce<{
      start: [number, number],
      end: [number, number],
      distance: number,
    }[]>((acc, pla) => {
      const playerKey0 = playersPoints
        && Object.keys(playersPoints).length > 1 && playersPoints[pla[0]]
        || null;

      const playerKey1 = playersPoints
        && Object.keys(playersPoints).length > 1 && playersPoints[pla[1]]
        || null;

      const distance = playerKey0 && playerKey1
      && playerKey0.x_0 !== 0 && playerKey1.x_0 !== 0
        ? Math.sqrt(
          (playerKey1.x_0 - playerKey0.x_0) ** 2
          + (playerKey1.y_0 - playerKey0.y_0) ** 2,
        ).toFixed(1)
        : 0;

      acc.push({
        start: playerKey0 ? [playerKey0.x, playerKey0.y] : [0, 0],
        end: playerKey1 ? [playerKey1.x, playerKey1.y] : [0, 0],
        distance: toNumber(distance),
      });

      return acc;
    }, []);
  }

  return [];
};
