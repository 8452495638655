import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from '@material-ui/lab';
import { useLazyQuery } from '@apollo/client';
import clientSettings from '../../lib/client_settings';
import { PING_EXPORT } from '../../query/export';
import GpexeVersion from '../GpexeVersion';

function NetworkMonitor() {
  const { t } = useTranslation();
  const [status, setStatus] = useState(0);

  const checkConnection = async () => {
    const time1 = performance.now();
    await fetch(clientSettings.public.gpexeGraphUrl.replace('/ui/v2/', '/ping/'))
      .then((d) => d.text())
      .then((d) => {
        if (typeof d === 'string' && d === 'pong') {
          const time = performance.now() - time1;
          setStatus(
            time > 60000
              ? 2
              : time > 10000
                ? 1
                : 0,
          );
        } else {
          console.warn('Cannot monitor network status, please check server response for route "/ping".');
        }
      })
      .catch((e) => {
        console.error(e);
        setStatus(2);
      });
  };

  useEffect(() => {
    setInterval(() => {
      checkConnection();
    }, 10000);
  }, []);

  if (status === 0) {
    return <GpexeVersion />;
  }

  // @todo log to sentry a slow client?

  return status === 1
    ? (
      <Alert
        severity="warning"
        className="compact"
      >
        {t('network.status.slow')}
      </Alert>
    )
    : (
      <Alert
        severity="error"
        className="compact"
      >
        {t('network.status.offline')}
      </Alert>
    );
}

export default NetworkMonitor;
