import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import BuildIcon from '@material-ui/icons/Build';
import { Box, Paper } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useMutation } from '@apollo/client';
import { REVOKE_TOKEN } from 'mutation/authentication';
import { ACTIVATE_RESET_IMAGE_CACHE, RESET_IMG_CACHE_KEY, resetExelioImageCache } from 'storage/imgDbCache';
import {  useNavigate } from 'react-router';
import SettingsIcon from '@images/IconSidebar/settings.svg';
import Sidebar from './Sidebar';
import Routes, { titlePrefix } from './Routes';
import { AppBarContext } from './AppBarContext';
import GlobalHeader from './GlobalHeader';
import ContentWrapper from './ContentWrapper';
import Footer from './layout/Footer';
import { debounce } from './utils/utils';
import { APP_STATES, AppState, useGlobalState } from '../lib/global';
import { Row } from './layout/Row';
import { Column } from './layout/Column';
import { AuthenticatorContext, ThemeOptionsContext } from '../Authenticator';
import { RolesTypes } from '../types/acl';
import { UserCanProps, UserContext, isSupportStaff } from './ACLWrapper';
import { USER_PERMISSIONS, UserPermissionsRes, UserPermissionsVars } from '../query/user';
import { MeType } from '../models/me';
import { withMe } from './MeUserWrapper';
import MainLoader from './layout/MainLoader';
import { useQueryCached } from './utils/graphql';
import { handleLogout } from './Account';
import DialogBox from './DialogBox';
import { useLocation } from 'react-router-dom';
// import CLIENT_SETTINGS from '../lib/client_settings';

export const largeScreen = () => window.innerWidth > 1440;

const e11_title = "We are updating the databases"
const e11_message = "Due to the issues encountered, we are making updates that will take a few hours. <br/> We kindly ask for your patience, thank you."
//const isServer11 = CLIENT_SETTINGS?.public?.gpexeBackendUrl?.includes('e11');
const isServer11 = false;

export const MaintenanceMessage = () => (
  <div className="maintenance-message">
    <Helmet>
      <title>{`${titlePrefix} - maintenance`}</title>
    </Helmet>
    <Paper elevation={12}>
      <Box p={3}>
        <Row>
          <Column md={1}>
            <Box height="100%" display="flex" justifyContent="center" marginTop="5px">
              <BuildIcon fontSize="large" />
            </Box>
          </Column>

          <Column md={11}>
            <h3>
              <Trans
                i18nKey={isServer11 ? "" : "global.maintenance.title"}
                defaults={isServer11 ? e11_title : "The gpexe web app is currently undergoing <br/> maintenance as we install a new release."}
              />
            </h3>
            <p>
              <Trans
                i18nKey={isServer11 ? "" : "global.maintenance.text"}
                defaults={isServer11 ? e11_message : "This process is expected to take around 15 minutes. <br/> Thank you for your understanding, and we'll be back online shortly!"}
              />
            </p>
          </Column>
        </Row>
      </Box>
    </Paper>
  </div>
);

export const checkUserPermissions = (isStaff, user, section, type, permission) => (
  isStaff
      || user // user is null only if me.isStaff
      && user[section]
      && user[section][type]
      && (permission.constructor === Array
        ? permission.every((el) => user[section][type].includes(el))
        : user[section][type].includes(permission))
);

function Layout(props: { me: MeType }) {
  const { me } = props;
  const [open, setOpen] = React.useState(largeScreen);
  const [appbarContent, setAppbarContent] = React.useState(<div />);
  const [appState] = useGlobalState(AppState);
  const prevSize = React.useRef(0);
  const { isTokenValid } = useContext(AuthenticatorContext);
  const { themeOptions, setThemeOptions } = useContext(ThemeOptionsContext);
  const pathName = window.location.pathname;
  const resetPassword = pathName.match(/^\/reset/);
  const refreshToken = localStorage.getItem('exelio_refresh_token');
  const isTeamExpired = me.lastTeam?.endDate && ((new Date(me.lastTeam.endDate)).getTime() < (new Date()).getTime());
  const [hasBeenWarned, setHasBeenWarned] = useState(false);
  const navigate = useNavigate();
  const {pathname} = useLocation();

  useEffect(() => {
    if (me?.lastTeam?.id) {
      const warnedStatus = localStorage.getItem(me.lastTeam.id);
      setHasBeenWarned(warnedStatus === 'true');
    }
  }, [me?.lastTeam?.id]);

  const markAsWarned = () => {
    if (me?.lastTeam?.id) {
      setHasBeenWarned(true);
      localStorage.setItem(me?.lastTeam?.id, 'true');
    }
  };

  const [revokeToken] = useMutation(REVOKE_TOKEN, {
    onCompleted() {
      handleLogout(pathName);
    },
  });

  useEffect(() => {
    if (resetPassword) {
      revokeToken({ variables: { refreshToken } });
    }
  }, [resetPassword]);

  const toggleDrawer = () => {
    setThemeOptions({
      ...themeOptions,
      ...{
        sideBarOpen: !open,
      },
    });
    setOpen((prev) => !prev);
    return true;
  };

  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      if (!largeScreen() && open && (window.innerWidth < prevSize.current)) {
        setThemeOptions({
          ...themeOptions,
          ...{
            sideBarOpen: false,
          },
        });
        setOpen(false);
      }
      prevSize.current = window.innerWidth;
    }, 150);

    prevSize.current = window.innerWidth;
    window.addEventListener('resize', debouncedHandleResize);
    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, []);

  const [user, setUser] = useState<{isStaff: boolean} & RolesTypes | null>(null); // @todo wip, tbd
  const userCan = useCallback((props: UserCanProps) => {
    const { section, type, permission } = props;
    return me && checkUserPermissions(me.isStaff, user, section, type, permission);
    // return (
    //   me
    //   && (
    //     me.isStaff
    //     || user // user is null only if me.isStaff
    //     && user[section]
    //     && user[section][type]
    //     && (permission.constructor === Array
    //       ? permission.every((el) => user[section][type].includes(el))
    //       : user[section][type].includes(permission))
    //   )
    // );
  }, [user, me]);

  const userPermissionsValue = { user, setUser, userCan };
  const isClubLocked = me?.lastTeam?.club?.locked;

  const {
    loading, error, data, refetch,
  } = useQueryCached<UserPermissionsRes, UserPermissionsVars>(USER_PERMISSIONS, {
    skip: !isTokenValid, // skip if jwt token not valid
    variables: {
      teamId: me?.lastTeam?.id,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (
      !loading
      && error === undefined
      && !!data
    ) {
      setUser({
        ...{
          isStaff: me.isStaff,
          isSupportStaff: isSupportStaff(me?.email),
        },
        ...JSON.parse(data.userPermissions),
      });
    }
  }, [loading, error, data]);

  useEffect(() => {
    if (me?.lastTeam?.id && isTokenValid) { // skip if jwt token not valid
      refetch();
    }
  }, [me]);

  useEffect(() => {
    const resetExelioImgCounter = localStorage.getItem(RESET_IMG_CACHE_KEY);
    const resetCounter = resetExelioImgCounter ? Number(resetExelioImgCounter) : 0;
    if (resetCounter < ACTIVATE_RESET_IMAGE_CACHE) {
      localStorage.setItem(RESET_IMG_CACHE_KEY, String(resetCounter + 1));
      resetExelioImageCache();
    }
  }, []);

  const { t } = useTranslation();

  const isFullscreenComponent = useMemo(() => ['/fullscreen-session-player'].includes(pathname), [pathname]);

  if (user === null) {
    return <MainLoader />;
  }

  return (
    <AppBarContext.Provider value={{
      content: appbarContent,
      setContent: setAppbarContent,
    }}
    >
      <UserContext.Provider value={userPermissionsValue}>
        <div className={`body-wrapper ${appState !== APP_STATES.normal ? 'maintenance' : ''} ${isFullscreenComponent ? 'fullscreen-component' : ''}`}>
          {
            !isFullscreenComponent
            && (
              <Sidebar
                open={open}
                me={me}
                toggleDrawer={toggleDrawer}
              />
            )
          }

          <div id="content_gpexe" className={isClubLocked ? '' : `content_gpexe--${open ? 'open' : 'closed'}`}>
            {
              !(isClubLocked || isFullscreenComponent)
              && (
                <IconButton
                  onMouseDown={toggleDrawer}
                  onTouchEnd={toggleDrawer}
                  style={{
                    width: 24,
                    height: 24,
                    zIndex: 999,
                    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 7px rgba(0, 0, 0, 0.12)',
                  }}
                  className="sidebar-trigger"
                >
                  {open && <ChevronLeftRoundedIcon />}
                  {!open && <ChevronRightRoundedIcon />}
                </IconButton>
              )
            }

            {
              !isFullscreenComponent
              && (
                <GlobalHeader />
              )
            }

            <ContentWrapper>
              <Routes
                lastTeamId={me?.lastTeam?.id}
                templateId={me?.template?.id}
                kpi={me?.template?.kpi}
                clubId={me?.lastTeam?.club?.id}
                id={me?.id}
                hasRpeLastTeam={me?.hasRpeLastTeam}
              />
            </ContentWrapper>

            {
              !(isClubLocked || isFullscreenComponent)
              && <Footer menuIsOpen={open} />
            }

            {
              !isFullscreenComponent
              && (
                <DialogBox
                  title={t('dialog.title.warning', 'warning')}
                  buttonCancel={t('drawer.actions.close') || 'close'}
                  buttonAction={t('athlete.tab.teams') || 'teams'}
                  buttonColor="primary"
                  content={t('teams.dialog.teamExpiredWarning') || 'Current team is expired. Please consider creating a new team or selecting a different one.'}
                  visible={isTeamExpired && !hasBeenWarned || false}
                  onClose={markAsWarned}
                  onClick={() => {
                    markAsWarned();
                    navigate('/teams');
                  }}
                  customIcon={<SettingsIcon />}
                />
              )
            }

          </div>
        </div>
      </UserContext.Provider>
    </AppBarContext.Provider>
  );
}

export default withMe(Layout);
