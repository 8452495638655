import { WINDOW_HEIGHT, WINDOW_WIDTH } from './constants';

export function findMinMaxX(pathData) {
  const xData = pathData.map((e) => parseFloat(e.x)).filter((x) => !Number.isNaN(x));
  return { minX: Math.min(...xData), maxX: Math.max(...xData) };
}

export function findMinMaxY(pathData) {
  const yData = pathData.map((e) => parseFloat(e.y)).filter((y) => !Number.isNaN(y));
  return { minY: Math.min(...yData), maxY: Math.max(...yData) };
}

export function getScale(width, height, boxWidth, boxHeight) {
  const scaleX = (boxWidth || WINDOW_WIDTH) / width;
  const scaleY = (boxHeight || WINDOW_HEIGHT) / height;
  return Math.min(scaleX, scaleY);
}
export function isGroundVertical(groundData) {
  return (
    (groundData.vertexBX.value - groundData.vertexAX.value) ** 2 + (groundData.vertexBY.value - groundData.vertexAY.value) ** 2
    > (groundData.vertexCX.value - groundData.vertexBX.value) ** 2 + (groundData.vertexCY.value - groundData.vertexBY.value) ** 2
  );
}
export function computeAngle(groundData) {
  if (groundData.vertexAX.value === groundData.vertexBX.value) return 0;
  const q = (groundData.vertexAX.value * groundData.vertexBY.value
      - groundData.vertexBX.value * groundData.vertexAY.value)
    / (groundData.vertexAX.value - groundData.vertexBX.value);

  const side1 = q - groundData.vertexAY.value;
  const side2 = groundData.vertexAX.value;

  let angle = side1 === 0 ? 0 :  Math.atan(side2 / side1);

  if (isGroundVertical(groundData)) angle += Math.PI / 2;
  return angle;
}
export function evaluateXFlip(groundData) {
  const aX = 0;
  const aY = 0;
  const cX = groundData.vertexCX.value - Math.abs(groundData.vertexAX.value);
  const bY = groundData.vertexBY.value - Math.abs(groundData.vertexAY.value);
  return aX > cX && aY > bY;
}
export function evaluateYFlip(groundData) {
  const aX = 0;
  const aY = 0;
  const cX = groundData.vertexCX.value - Math.abs(groundData.vertexAX.value);
  const bY = groundData.vertexBY.value - Math.abs(groundData.vertexAY.value);
  return aX < cX && aY < bY;
}
export function flipPoint(p, groundData) {
  const point = p;
  if (groundData) {
    if (evaluateXFlip(groundData)) point.x *= -1;
    if (evaluateYFlip(groundData)) point.y *= -1;
  }
  return point;
}
export function rotate(point, angle) {
  const cos = Math.cos(angle);
  const sin = Math.sin(angle);
  const nx = cos * point.x - sin * point.y;
  const ny = cos * point.y + sin * point.x;
  return { x: nx, y: ny };
}
export function flipX(p, o) {
  return o.x + (p.x - o.x) * Math.cos(Math.PI) - (p.y - o.y) * Math.sin(Math.PI);
}
export function flipY(p, o) {
  return o.y + (p.x - o.x) * Math.sin(Math.PI) + (p.y - o.y) * Math.cos(Math.PI);
}
export function convertDegrees(angleInRadians) {
  return (angleInRadians * 180.0) / Math.PI;
}
export function convertRadians(angleInDegrees) {
  return (angleInDegrees * Math.PI) / 180.0;
}
export function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
  const angleInRadians = convertRadians(angleInDegrees - 90);

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
}
export function describeArc(x, y, radius, startAngle, endAngle) {
  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);

  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

  const d = ['M', start.x, start.y, 'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(' ');

  return d;
}
