import { ButtonProps } from '@material-ui/core/Button';
import { IconButtonProps } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@images/IconHeader/delete.svg';
import BaseButton from './BaseButton';

type PropsIn = ButtonProps & IconButtonProps & {
  collapseOnMobile?: boolean,
  collapseOnSmallDesktop?: boolean,
  // flat?: boolean,
  // active?: boolean,
  // isValidation?: boolean,
  // buttonSize?: 'sm' | 'md' | 'lg',
  onClick?: () => void,
  disabled?: boolean,
  tooltipText?: string,
  buttonSize?: 'sm' | 'md' | 'lg',
}

// @todo verifica modifiche componente e props non attive
export const DeleteButton = React.forwardRef((props: PropsIn, ref) => {
  const {
    collapseOnMobile,
    collapseOnSmallDesktop,
    // flat,
    // active,
    // isValidation,
    // buttonSize,
    onClick,
    disabled,
    tooltipText,
    buttonSize,
  } = props;

  const { t } = useTranslation();

  return (
    <BaseButton
      startIcon={<DeleteIcon />}
      onClick={onClick}
      disabled={disabled}
      tooltipText={tooltipText}
      collapseOnMobile={collapseOnMobile}
      collapseOnSmallDesktop={collapseOnSmallDesktop}
      buttonSize={buttonSize || 'md'}
    >
      {' '}
      {t('listField.delete', 'delete')}
      {' '}
    </BaseButton>
  );
});
