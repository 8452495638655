import { camelCaser } from 'components/utils/kpiLabelExtractor';
import { getNatureAbbr, secondsToHMMSS, secondsToMmSs } from '../utils/utils';
import { RPEExtremes } from '../constants';

export const formatCategory = (categoryName:string, categoryColor:string) => (
  (categoryName && categoryColor) ? (
    categoryColor.includes('_')
      ? `${categoryName}-${categoryColor.replace('_', '#')}`
      : `${categoryName}-${categoryColor}`
  ) : ''
);

export const formatCategories = (categories: string): { id: number, value: string, color: string }[] => {
  const categoriesList = categories.split(',');
  const categoriesListObj: { id: number, value: string, color: string }[] = [];
  categoriesList.forEach((c, index) => {
    const [value, color] = c.split('-');
    categoriesListObj.push({ id: index, value, color });
  });
  return categoriesListObj;
};

export const formatSessionType = (row, isRpeTable) => (isRpeTable ? getNatureAbbr(row?.teamsession?.nature || null) : row?.nature);

export const formatDuration = (row) => secondsToHMMSS(row?.duration?.value);
export const formatRPEDuration = (row) => secondsToHMMSS(row?.duration);

export const formatAthlete = (athlete, defaultValue) => athlete?.name || defaultValue;

export const formatDrill = (currentDrill, totalDrills) => (totalDrills > 0 ? currentDrill !== null ? `${currentDrill + 1}/${totalDrills}` : `${totalDrills}` : '-');

export const deltaDays = (date1, date2) => {
  if (date1 === undefined || date2 === undefined) return null;
  const date1Ms = new Date(date1).getTime();
  const date2Ms = new Date(date2).getTime();
  const deltaMs = date2Ms - date1Ms;
  return deltaMs / (1000 * 60 * 60 * 24);
};

export const getRpeIntervalsFromRpeType = (rpeType) => {
  if (rpeType === 'A_6') {
    return RPEExtremes.RPEType2;
  }
  return RPEExtremes.RPEType1;
};

export const computeEndTime = (start, seconds) => {
  const date = new Date(+new Date(`1970-01-01T${start}Z`) + seconds * 1000);
  const formattedStr = [date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()].map((n) => n.toString().padStart(2, 0)).join(':');
  return formattedStr;
};
