import {
  ApolloClient, ApolloLink, HttpLink, InMemoryCache,
} from '@apollo/client';
import { uniq } from 'ramda';
import * as localforage from 'localforage';
import { LocalForageWrapper, persistCache } from 'apollo3-cache-persist';
import CLIENT_SETTINGS from '../lib/client_settings';

const mergeArraysByField = (array1: any[] | undefined, array2: any[] | undefined, field: string) => {
  const mergedMap = new Map();
  const mergeObjects = (obj1, obj2) => ({ ...obj1, ...obj2 });

  (array1 || []).forEach((obj) => {
    mergedMap.set(obj[field], { ...obj });
  });

  (array2 || []).forEach((obj) => {
    if (mergedMap.has(obj[field])) {
      const mergedObj = mergeObjects(mergedMap.get(obj[field]), obj);
      mergedMap.set(obj[field], mergedObj);
    } else {
      mergedMap.set(obj[field], { ...obj });
    }
  });

  return Array.from(mergedMap.values());
};

type TimestampRange = [number, number];

const mergeTimestampRanges = (existing: TimestampRange[], incoming: TimestampRange): TimestampRange[] => {
  const result: TimestampRange[] = [];
  let [incomingStart, incomingEnd] = incoming;
  let merged = false;

  for (const [start, end] of existing) {
    // Check if the incoming range intersects with the current existing range
    if (end < incomingStart || start > incomingEnd) {
      // No intersection
      result.push([start, end]);
    } else {
      // There is an intersection, update the incoming range to be merged
      incomingStart = Math.min(incomingStart, start);
      incomingEnd = Math.max(incomingEnd, end);
      merged = true;
    }
  }

  if (merged) {
    result.push([incomingStart, incomingEnd]);
  } else {
    result.push(incoming); // No intersection with any existing range
  }

  // Sort the result array to keep it ordered
  return result.sort((a, b) => a[0] - b[0]);
}

const initApolloClient = async () => {
  const httpLink = new HttpLink({
    uri: CLIENT_SETTINGS.public.gpexeGraphUrl,
  });

  const cache = new InMemoryCache({
    typePolicies: {
      TeamTypeRes: {
        fields: {
          content: {
            // eslint-disable-next-line no-empty-pattern
            merge(existing: any[], incoming: any[], {}) {
              return uniq((existing || []).concat(incoming || []));
            },
          },
        },
      },
      GenericSeriesType: {
        keyFields: ['id', 'lastUpdate', 'drill'],
        merge: true,
      },
      TeamSessionType: {
        keyFields: ['id', 'drill'],
        fields: {
          matchDistance: {
            merge: true,
          },
          drills: {
            merge: true,
          },
        },
      },
      AthleteSessionType: { // @todo verificare
        keyFields: ['id'],
        fields: {
          averageP: { merge: true },
          equivalentDistanceIndex: { merge: true },
          accelerationEvents: { merge: true },
          kpi: {
            merge(existing: any[], incoming: any[], {}) {
              return mergeArraysByField(existing, incoming, 'name');
            },
          },
        },
      },
      GenericValueType: {
        keyFields: false,
      },
      GenericImuEventType: {
        keyFields: false,
      },
      PathType: {
        keyFields: false,
      },
      PowerEventPathType: {
        merge: true,
      },
      ClubType: {
        fields: {
          groundSet: {
            merge(existing: any[], incoming: any[], {}) {
              return uniq((existing || []).concat(incoming || []));
            },
          },
        },
      },
      DrillType: {
        keyFields: ['id', 'index', 'start', 'end'],
      },
      ViewerData: {
        keyFields: ['id'],
        fields: {
          intervals: {
            merge(existing: [number, number][] = [], incoming: [number, number], opts) {
              return mergeTimestampRanges(existing, [opts.variables.start, opts.variables.end]);
            }
          },
          data: {
            merge(existing = [], incoming, opts) {
              const existingPathData = existing || [];
              const newPathData = incoming || [];
              const existingPathDataPoints = existingPathData.map((p) => p[0]);

              const filteredPathData = newPathData
              .reduce((acc, pj) => {
                if (existingPathDataPoints.indexOf(pj[0]) === -1) {
                  acc.push(pj)
                }

                return acc;
              }, [])

              return [
                ...existingPathData,
                ...filteredPathData,
              ].sort((a, b) => a[0] - b[0]);
            }
          },
        }
      }
    },
  });

  localforage.config({
    name: 'queryPersistWorker',
  });
  await localforage.setDriver(localforage.INDEXEDDB);

  await persistCache({
    cache,
    storage: new LocalForageWrapper(localforage),
    debug: process.env.NODE_ENV !== 'production',
    maxSize: 100_000_000,
  });

  return new ApolloClient({
    link: ApolloLink.from([httpLink]),
    cache,
    defaultOptions: {
      query: {
        fetchPolicy: 'cache-first',
        errorPolicy: 'all',
      },
      watchQuery: {
        fetchPolicy: 'cache-first',
        errorPolicy: 'all',
      },
    },
  });
};

export const client = await initApolloClient();

export default initApolloClient;
