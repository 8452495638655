import React, { useState } from 'react';
import { AuthenticationDetails, CognitoUser, } from 'amazon-cognito-identity-js';
import UserPool from './UserPool'; // Configurazione della User Pool
import CLIENT_SETTINGS from './lib/client_settings';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export default function CognitoLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  /* usiamo le notifiche
   const [error, setError] = useState("");
   */

  /* @todo immagino fosse di debug (vedi utilizzo riga 84)
  const [token, setToken] = useState(null);
  */

  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate()

  // @todo rimuovere per produzione
  console.log(CLIENT_SETTINGS.public.useCognito, ' cognito');

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = ( e ) => {
    e.preventDefault();
    const cognitoRefreshTokenExpiration = Date.now() + 60 * 60 * 1000; //ms

    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });

    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    user.authenticateUser(authDetails, {
      onSuccess: ( data ) => {
        localStorage.setItem('exelio_cognito_token', data.getAccessToken().getJwtToken());
        localStorage.setItem('exelio_cognito_refresh_token', data.getRefreshToken().getToken());
        localStorage.setItem('exelio_cognito_refresh_token_exp', JSON.stringify(cognitoRefreshTokenExpiration));
        navigate('/dashboard')
      },
      onFailure: ( err ) => {
        console.error('Error:', err.message || JSON.stringify(err));
        // setError(err.message || "Errore durante l'autenticazione.");

        enqueueSnackbar(
          t('login.cognitoAuthUser.error', 'Error while authenticating, please try later.'),
          {
            variant: 'error',
            autoHideDuration: 5000,
          },
        );
      },
      newPasswordRequired: ( data ) => {
        console.log('New password required:', data);
        // setError("È richiesta una nuova password.");

        enqueueSnackbar(
          t('login.cognitoAuthUser.requiredNewPassword', 'New password required'),
          {
            variant: 'error',
            autoHideDuration: 5000,
          },
        );
      },
    });
  };

  return (
    <div style={{maxWidth: '400px', margin: '0 auto', padding: '1em'}}>
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={( e ) => setEmail(e.target.value)}
            required
            style={{width: '100%', padding: '0.5em', margin: '0.5em 0'}}
          />
        </div>
        <div>
          <label>Password</label>
          <input
            type="password"
            value={password}
            onChange={( e ) => setPassword(e.target.value)}
            required
            style={{width: '100%', padding: '0.5em', margin: '0.5em 0'}}
          />
        </div>
        <button type="submit" style={{padding: '0.5em 1em', marginTop: '1em'}}>
          Accedi
        </button>
      </form>
      {/* usiamo le notifiche
      error && <p style={{ color: "red" }}>{error}</p>
      */}
      {
        /* @todo immagino fosse di debug
        token && (
        <div>
          <h4>Access Token:</h4>
          <p style={{ wordWrap: "break-word" }}>{token}</p>
        </div>
      )*/}
    </div>
  );
};

export const cognitoRefreshToken = () => {
  const user = UserPool.getCurrentUser();

  // @todo rimuovere commenti per produzione
  console.log('user: ', user)
  if (user) {

    user.getSession(( err, session ) => {
      if (err) {
        console.error('Errore nel refresh del token:', err);
        return;
      }

      const refreshToken = session.getRefreshToken();
      console.log('refresh token: ', refreshToken);

      user.refreshSession(refreshToken, ( refreshError, newSession ) => {
        if (refreshError) {
          console.error('Errore nel refresh del token:', refreshError);
          return;
        }

        console.log('Nuovo access token ottenuto:', newSession.getAccessToken().getJwtToken());
        localStorage.setItem('exelio_cognito_token', newSession.getAccessToken().getJwtToken());
      });
    });
  }
}
